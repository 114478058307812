// Login.js

import React from "react";
import "./GoogleLogin.css";

const GoogleLogin = () => {
  const loginWithGoogle = () => {
    window.open("https://blog-backend-ivfv.onrender.com/auth/google", "_self");
  };

  return (
    <div className="login-page">
      <div className="form">
        <button className="login-with-google-btn" onClick={loginWithGoogle}>
          Sign In With Google
        </button>
      </div>
    </div>
  );
};

export default GoogleLogin;
