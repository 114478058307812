import React from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom'
const SAPISU = () => {
  const navigate=useNavigate();
  const goBack=()=>{
    navigate(-1);
  }
  return (
    <>
      <div class="container career">
        <div class="search-section">
          <div class="row careerrow">
            <div class="col-md-5">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Job Title"
              />
            </div>
            <div class="col-md-5">
              <input
                type="text"
                className="form-control"
                placeholder="Locations"
              />
            </div>

            <div class="col-md-2">
              <button className="careerbutton">Search Jobs</button>
            </div>
          </div>
        </div>

       
        <div
          className="d-flex justify-content-between align-items-center mb-3"
          style={{ padding: "100px 0px" }}
        >
          
          <div class="ms-auto">
            <button className="back-button" onClick={goBack}>Back</button>
            <nav aria-label="Job pagination">
              <ul class="pagination mb-0">
                <li class="page-item">
                  <a class="page-link" href="#">
                    «
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    4
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    5
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    »
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="container careerobjective">
        <h1>Sr. Consultant-ServiceNow Job</h1>
        <h3>
          <strong>Date</strong>: sep 16,2024
        </h3>
        <h3>
          <strong>Job Requisition Id</strong>:58693
        </h3>
        <h3>
          <strong>Date</strong>: Pune, MH,In
        </h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
          sagittis, mauris nec ultrices ultrices, nisl dolor pretium tellus, in
          tempor nunc est vel est. Donec interdum tortor quis lacus molestie
          porta. Aenean maximus orci eu ante congue auctor. Maecenas ac lobortis
          justo. Duis ut sodales mi. Nullam at dui arcu. Donec ut posuere risus,
          at lobortis lorem. Maecenas vitae ullamcorper velit. Nulla elementum,
          orci ut sodales consectetur, urna urna lacinia ligula, et dapibus
          turpis dolor vel ex. Aliquam nulla nisl, tincidunt vitae malesuada sit
          amet, convallis sit amet ligula.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
          sagittis, mauris nec ultrices ultrices, nisl dolor pretium tellus, in
          tempor nunc est vel est. Donec interdum tortor quis lacus molestie
          porta.{" "}
        </p>
        <div className="applybutton">
          <Link className="nav-link" to="/login">
          <button>
            Apply Now
          </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SAPISU;
