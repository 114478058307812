import React, { useState, useRef } from 'react';
import contact from "./Images/img-contact.png";
import user from "./Images/ic-user.png"; // User icon
import company from "./Images/ic-company.png"; // Company icon
import email from "./Images/ic-email.png"; // Email icon
import message from "./Images/ic-message.png"; // Message icon
import phone from "./Images/ic-phone.png"; // Phone icon
import servicesIcon from "./Images/ic-services.png"; // Services icon
// Phone error popup component
const PhoneErrorPopup = ({ message, inputRef }) => {
  return (
    <div 
      className="phone-error-popup"
      style={{
        position: 'absolute',
        top: inputRef.current.offsetTop + inputRef.current.offsetHeight,
        left: inputRef.current.offsetLeft,
        backgroundColor: '#f8d7da',
        padding: '5px',
        borderRadius: '5px',
        border: '1px solid #f5c2c7',
        color: '#842029',
        zIndex: 1000,
        whiteSpace: 'nowrap'
      }}
    >
      {message}
    </div>
  );
};

// ContactForm Component with all the original functionality
const ContactForm = ({ services, onSubmitSuccess }) => {
  const [formData, setFormData] = useState({
    name: '',
    service: '',
    email: '',
    phone: '',
    company: '',
    message: '',
  });

  const [phoneError, setPhoneError] = useState("");
  const phoneInputRef = useRef(null);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "phone") {
      setPhoneError("");
    }
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    const { phone } = formData;
    if (!/^\d{10}$/.test(phone)) {
      setPhoneError("Please enter a valid 10-digit phone number.");
      return;
    }

    try {
      const response = await fetch('https://blog-backend-ivfv.onrender.com/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // eslint-disable-next-line no-unused-vars
        const data = await response.json();
        alert('Form Submitted!!');
        onSubmitSuccess(); // Trigger success callback if provided
        formData("")
      } else {
        console.error('Error submitting form:', response.statusText);
        alert('There is an error submitting the form. Try again');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please try again later.');
    }
  };

  return (
    <div className="container-fluid contact-section" id="contact-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <img src={contact} alt="Contact" title="Contact US" className="img-fluid" />
            </div>
            <div className="col-md-4">
              <h2 className="contact-header">Get In Touch</h2>
              <p className="contact-description">Please fill the below form.</p>
              <form onSubmit={handleSubmit}>
                <div className="">
                  <div className="input-group">
                    <span className="input-group-text input-icon">
                      <img src={user} alt="User Icon" title="user"/>
                    </span>
                    <input
                      type="text"
                      className="form-control inputStyle"
                      placeholder="Your Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      style={{ padding: '14px' }} 
                      required
                    />
                  </div>
                </div>
                <div className="">
                  <div className="input-group" style={{ flexWrap: "nowrap" }}>
                    <span className="input-group-text input-icon">
                      <img src={servicesIcon} alt="Services Icon" title="Services"/>
                    </span>
                    <select 
                      className="inputStyle select-style" 
                      name="service"
                      value={formData.service}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Services</option>
                      <option value="web-development">Web Development</option>
                      <option value="app-development">App Development</option>
                      <option value="seo-services">SEO Services</option>
                      <option value="cloud-solutions">Cloud Solutions</option>
                    </select>
                  </div>
                </div>

                <div className="">
                  <div className="input-group">
                    <span className="input-group-text input-icon">
                      <img src={email} alt="Email Icon" title="Email"/>
                    </span>
                    <input
                      type="email"
                      className="form-control inputStyle"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      style={{ padding: '14px' }} 
                      required
                    />
                  </div>
                </div>

                <div className="">
                  <div className="input-group">
                    <span className="input-group-text input-icon">
                      <img src={phone} alt="Phone Icon" title="phone"/>
                    </span>
                    <input
                      type="tel"
                      className="form-control inputStyle"
                      placeholder="Phone"
                      name="phone"
                      maxLength={10}
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      pattern="[0-9]{10}"
                      title="Please enter a valid 10-digit phone number"
                      ref={phoneInputRef}
                      style={{ padding: '14px' }} 
                    />
                    {phoneError && <PhoneErrorPopup message={phoneError} inputRef={phoneInputRef} />}

                  </div>
                </div>

                <div className="">
                  <div className="input-group">
                    <span className="input-group-text input-icon">
                      <img src={company} alt="Company Icon" title="company" />
                    </span>
                    <input
                      type="text"
                      className="form-control inputStyle"
                      placeholder="Company"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                      style={{ padding: '14px' }} 
                      required
                    />
                  </div>
                </div>

                <div className="">
                  <div className="input-group">
                    <span className="input-group-text input-icon1">
                      <img src={message} alt="Message Icon" title="message" className="image" />
                    </span>
                    <textarea
                      className="form-control inputStyle1"
                      placeholder="Message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary button3"  alt="Submit" title="Submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ContactForm;