

import React,{useState} from "react";
import Android from "./Images/Android.jpeg";
import { Image } from "react-bootstrap";
import logo11 from "./Images/arrow_outward.png";
import circle from "./Images/check_circle.png";
import node1 from "./Images/node1.jpeg";
import group from "./Images/Group.png";
import group1 from "./Images/Group1.png";
import SEO from "./Images/SEO.png";
import search1 from "./Images/search1.png";
import SEO1 from "./Images/digital_wellbeing.png";
import SEO2 from "./Images/add_task.png";
import SEO3 from "./Images/SEO3.png";
import SEO4 from "./Images/diversity_1.png";
import SEO5 from "./Images/SEO5.png";
import SEO6 from "./Images/SEO6.png";
import Accordion from "react-bootstrap/Accordion";
import Modal1 from "./Modal1";
const IOS = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={SEO}/>
          </div>
          <div className="col-md-8">
            <h2>Search Engine Optimization</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid nativecolor">
        <div className="container native">
          <h2>Dominate Search Results: Unleash the Power of SEO</h2>
          <p>
          Bestowal Systems & Services is a dynamic digital marketing agency renowned for its strategic approach and proven expertise in driving online success for small businesses. We excel in creating tailored digital strategies that encompass SEO, content marketing, social media management, and comprehensive analytics. Our team of specialists is dedicated to maximizing client ROI by implementing data-driven strategies that resonate with target audiences and elevate brand visibility. Unlike cookie-cutter solutions, we prioritize personalized client partnerships, ensuring that every campaign is meticulously crafted to meet unique business objectives. With a commitment to transparency and measurable results, we empower businesses to thrive in the digital landscape through continuous optimization
          {!isExpanded ? (
              <span className="dots">...</span>
            ) : (
              <span className="more">
               and adaptation to industry trends. Whether it's enhancing online presence, boosting conversions, or scaling growth, Bestowal Systems & Services delivers impactful digital marketing solutions that drive lasting success.
              </span>
            )}
          </p>
          <button className="sapbtn" onClick={toggleExpand}>
            {isExpanded ? "Read Less" : "Read More"}
            <Image src={logo11} className="img0" alt="Read more icon" />
          </button>
        </div>
      </div>
      <div className=" container android">
        <h2>Solutions for Your Needs</h2>
        <div className="row">
          <div className="col-md-4">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={SEO1} className="seoimg" />
              </span>
              <h4>Value For Business</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={SEO2} className="seoimg" />
              </span>
              <h4>Proven Result</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={SEO3} className="seoimg" />
              </span>
              <h4>Industry Experience</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={SEO4} className="seoimg" />
              </span>
              <h4>Team Of Experts</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={SEO5} className="seoimg" />
              </span>
              <h4>Cutting Edge Tools</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={SEO6} className="seoimg" />
              </span>
              <h4>100% Transparency</h4>
            </div>
          </div>
        </div>
        <button onClick={handleOpenModal}>
            Get In Touch <Image src={logo11} className="img6" alt="Arrow" />
          </button>
          {isModalOpen && <Modal1 onClose={handleCloseModal} />}
      </div>
      <div className="container-fluid seo">
        <div className="container android">
          <h2>Boost Your Business Growth with Professional SEO</h2>
          <p>
            Invest in SEO services for a competitive edge, better local ranking,
            brand growth, and customer insights. SEO takes time but delivers
            long-term benefits.
          </p>
          <div class="container accordion">
            <div class="accordion-container">
             
              <div class="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-1"
                  class="accordion-checkbox"
                />
                <label for="accordion-item-1" class="accordion-header">
                  <span>01</span>
                  <p>Raising Credibility</p>
                  <div class="arrow"></div>
                </label>
                <div class="accordion-body">
                  <ul class="custom-list">
                    <li>Your brand value will increase with better content.</li>
                    <li>
                      Your website will draw more visitors if it is educational
                      and up-to-date.
                    </li>
                    <li>
                      Higher rankings from search engines boost credibility and
                      authority.
                    </li>
                    <li>
                      Efficient SEO services improve website speed and enhance
                      content quality.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-2"
                  class="accordion-checkbox"
                />
                <label for="accordion-item-2" class="accordion-header">
                  <span>02</span>
                  <p>Enhances User Interaction</p>
                  <div class="arrow"></div>
                </label>
                <div class="accordion-body">
                  <ul class="custom-list">
                    <li>Your brand value will increase with better content.</li>
                    <li>
                      Your website will draw more visitors if it is educational
                      and up-to-date.
                    </li>
                    <li>
                      Higher rankings from search engines boost credibility and
                      authority.
                    </li>
                    <li>
                      Efficient SEO services improve website speed and enhance
                      content quality.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-3"
                  class="accordion-checkbox"
                />
                <label for="accordion-item-3" class="accordion-header">
                  <span>03</span>
                  <p>Increases Conversion Rates</p>
                  <div class="arrow"></div>
                </label>
                <div class="accordion-body">
                  <ul class="custom-list">
                    <li>Your brand value will increase with better content.</li>
                    <li>
                      Your website will draw more visitors if it is educational
                      and up-to-date.
                    </li>
                    <li>
                      Higher rankings from search engines boost credibility and
                      authority.
                    </li>
                    <li>
                      Efficient SEO services improve website speed and enhance
                      content quality.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-4"
                  class="accordion-checkbox"
                />
                <label for="accordion-item-4" class="accordion-header">
                  <span>04</span>
                  <p>Business Recognition</p>
                  <div class="arrow"></div>
                </label>
                <div class="accordion-body">
                  <ul class="custom-list">
                    <li>Your brand value will increase with better content.</li>
                    <li>
                      Your website will draw more visitors if it is educational
                      and up-to-date.
                    </li>
                    <li>
                      Higher rankings from search engines boost credibility and
                      authority.
                    </li>
                    <li>
                      Efficient SEO services improve website speed and enhance
                      content quality.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container android">
        <div className="row androidrow1">
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>360-Degree Solutions</h2>
                  <p>
                  In our opinion, search engine optimization (SEO) is both an art and a science. As a result, in order to give you results that are both exceptional and sturdy, we strike a balance between a number of elements.                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>Reliability and Transparency</h2>
                  <p>
                  We believe that you should always be aware of the strategies we are implementing for your business. We do this because we want you to fully trust what we are doing. As a result, we make care to keep you informed about everything.                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>Enhance ROI and Sales</h2>
                  <p>
                  Our SEO consulting services in India guarantee that a business gains from an SEO strategy. We boost your sales and return on investment with our SEO techniques.                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>Keyword Targeting</h2>
                  <p>
                  We focus on identifying keywords that are pertinent to your business, product, or service and that also meet the needs of your target market.                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>Performance Evaluation</h2>
                  <p>
                  Our daily, weekly, and monthly reports let you know how your website is doing and what is scheduled for the upcoming weeks.                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>Dedicated Project Manager</h2>
                  <p>
                  For improved coordination and communication, we offer a dedicated account manager since we comprehend the value of a human touch.                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>Reasonably Priced Services</h2>
                  <p>
                  We offer moderately cost services, and you can be confident that you will always get what you paid for. Hidden fees with shockingly high prices are not something we support.                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>Simple CRM Tools</h2>
                  <p>
                  By giving updates on ongoing work, we use CRM systems to assist develop and manage connections with our clients. The tool is straightforward and user-friendly.                  </p>
              </div>
            </div>
          </div>
        </div>

        </div>
    </>
  );
};

export default IOS;
