import React from 'react'
import Conclusion from "./Images/Digital-transformation.png";
import oip from "./Images/OIP.jpeg";
const Project = () => {
  return (
    <>
       <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={oip}/>
          </div>
          <div className="col-md-8">
          <h2>Bestowal Systems And Services</h2>
            <p>
              From concept to deployment, streamline your engineering workflow.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid nativecolor">
        <div className="container process">
          <h2>
          Enhancing Forecasting and Planning for a Leading Chemical Company in Singapore
          </h2>
          <div className="container">
            <section class="client-overview">
              <h3>Client Overview</h3>
              <p>
              Our client is a leading chemical company based in Singapore, known for its high-quality products and extensive distribution network.
              </p>
            </section>

            <section class="technologies-used">
              <h3>Technologies Used</h3>
              <ul>
                <li>SAP IBP (Integrated Business Planning)</li>
                <li>SAP APO (Advanced Planning and Optimization)</li>
                <li>SAP ECC (ERP Central Component)</li>
                <li>ABAP (Advanced Business Application Programming)</li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="key-challenges">
              <h3>Key Challenges</h3>
              <p>The client faced several significant challenges:</p>
              <ul>
                <li>
                  <strong>	Multiple Business Units: </strong> Coordinating planning across various business units proved complex.
                </li>
                <li>
                  <strong>	Unstable Production Environment: </strong> The existing production environment was unstable and performance was slow.
                </li>
                <li>
                  <strong>	Forecasting Limitations: </strong> Planners were unable to forecast accurately for small distributors due to the absence of suitable templates. The legacy tool required extensive disaggregation steps for forecasting.
                </li>
                <li>
                  <strong>	Need for Standardization: </strong> Implementing IBP Unconstrained Heuristics was essential to standardize the material planning process for the mid to long-term horizon.
                </li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="solution-implementation">
              <h3>Solution and Implementation</h3>
              <p>
              Our solution focused on addressing these challenges through the implementation of SAP IBP:
              </p>
              <ul>
                <li>
                  <strong>Simplified Planning Process: </strong> We simplified the planning process across functions and laid the groundwork for adapting the entire Sales & Operations Planning (S&OP) process to utilize SAP IBP.
                </li>
                <li>
                  <strong>Advanced Planning Tools:</strong> SAP IBP provided tools such as Segmentation, Time Series Analysis, and Outlier Corrections, reducing the workload for demand planners and enhancing forecasting accuracy.
                </li>
                <li>
                  <strong>Proactive S&OP Discussions: </strong>The implementation of IBP facilitated proactive discussions, leading to an optimal S&OP process.
                </li>
                <li>
                  <strong>Capacity Issue Anticipation: </strong> The new system enhanced the ability to anticipate potential capacity issues in the long term, improving overall planning and resource management.
                </li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="value-delivered">
              <h3>Value Delivered</h3>
              <p>The implementation of our solution brought substantial benefits to the client:</p>
              <ul>
                <li>
                  <strong>Streamlined Processes:</strong> The demand planning
                  process became more efficient and cohesive, enhancing overall
                  functionality.
                </li>
                <li>
                  <strong>Cost Reduction:</strong> Consolidating systems and
                  improved inventory management led to lower maintenance costs
                  and reduced inventory carrying costs.
                </li>
                <li>
                  <strong>Enhanced Accuracy:</strong> The standardized processes
                  improved forecast accuracy, contributing to better business
                  outcomes.
                </li>
                <li>
                  <strong>Operational Efficiency:</strong> The client
                  experienced reduced delays and inconsistencies, leading to
                  more reliable operations.
                </li>
              </ul>
            </section>
          </div>
         
        </div>
      </div>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-6">
            <img src={Conclusion} width={450}/>
          </div>
          <div className="col-md-6">
          <h2>Conclusion</h2>
            <p>
            Through strategic planning and execution, we transformed the client's demand planning process, delivering a robust, efficient, and cost-effective solution. This case study exemplifies our commitment to driving operational excellence and delivering tangible business benefits for our clients.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Project
