
import React from 'react';
import { Link } from "react-router-dom";
import './App.css'; // Import the CSS file
import linkedin from "./Images/ic-linkedin.png";
import facebook from "./Images/ic-facebook.png";
import twitter from "./Images/ic-twitter.png";
import Instagram from "./Images/ic-instagram.png";
import YT from "./Images/ic-youtube.png";
import pinterest from "./Images/ic-pintrest.png";
import reddit from "./Images/ic-reddit.png";
import logo1 from './Images/BestowalFooter.png'; // Adjust the path as necessary
import './App.css'; // Import the CSS file
const Footer = () => {
  // Function to handle scroll-to-top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="container-fluid footer">
    <div className="container">
      <div className="row">
        <div className="col-md-5 footer-section">
          <img src={logo1} alt="Bestowal Footer Logo" title="Bestowal Logo" className="footer-logo mb-3" />
          <p>Bestowal Systems and Services is a company which is involved in product<br/> development and consultancy services for various technologies.</p>
          <h6 className="address">
           <b>Office Address:</b> First floor, Above CSB Bank, Opp. Thyssenkrupp Industry, Near Ambedkar Chowk, Pimpri, Pune - Maharashtra, India - 411 018.
          </h6>
          <tel className='contactno'>+91 94055 30011</tel>
        </div>
        <div className="col-md-7 footer-section1">
          <div className="row footerrow">
              <div className="col-md-3">
              <h4>Services</h4>
              <ul className="list-unstyled">
                <li><Link className="nav-link" to="/sap" onClick={scrollToTop}>
                      SAP Services
                    </Link></li>
                <li><Link className="nav-link" to="/native" onClick={scrollToTop}>
                      Mobile Services
                    </Link></li>
                <li><Link className="nav-link" to="/react" onClick={scrollToTop}>
                      Web Services
                    </Link></li>
                <li><Link className="nav-link" to="/industrylanding" onClick={scrollToTop}>
                      Industries
                    </Link></li>
                <li><Link className="nav-link" to="/products" onClick={scrollToTop}>
                      Products
                    </Link></li>
                {/* <li><Link className="nav-link" to="/digital" onClick={scrollToTop}>
                      Digital Marketing
                    </Link></li> */}
              </ul>
            </div>
            <div className="col-md-2">
              <h4>Company</h4>
              <ul className="list-unstyled">
                <li><Link className="nav-link" to="/careerlanding" onClick={scrollToTop}>
                      Career
                    </Link></li>
                {/* <li><a href="#policy">Policy</a></li> */}
                <li><Link className="nav-link" to="/about" onClick={scrollToTop}>
                      About US
                    </Link></li>
              </ul>
            </div>
            <div className="col-md-2">
              <h4>Support</h4>
              <ul className="list-unstyled">
                <li><Link className='nav-link' to="/contact-us" onClick={scrollToTop}>Contact us</Link></li>
                <li><Link className='nav-link' to="/blog" onClick={scrollToTop}>Blogs</Link></li>
              </ul>
            </div>
            <div className="col-md-5">
              <h4>Contact</h4>
              <ul className="list-unstyled">
                <li className='hr'><a href="mailto:info@bestowal.com">HR: <span>hr@bestowalsystems.com</span></a></li>
                <li className='carrier'><a href="mailto:support@bestowal.com">Careers: <span>careers@bestowalsystems.com</span></a></li>
                <li className='business'><a href="mailto:sales@bestowal.com">Business: <span>contact@bestowalsystems.com</span></a></li>
              </ul>
            </div>
          </div>
          
           <div className="row">
            <div className="col">
              <h5>Social</h5>
              <div className="social-icons mr-10">

              <a href="https://www.linkedin.com/company/bestowal-systems-and-services-private-limited/" className="linkedin" target="_blank" rel="noopener noreferrer">
              <img src={linkedin } className='social-icon' alt='Bestowal_linkedin_Account' title='Bestowal_Linkedin'/>
                </a>
              <a href="https://www.facebook.com/bestowalsystems" className="pinterest" target="_blank" rel="noopener noreferrer">
                <img src={facebook} className='social-icon' alt='Bestowal_facebook_Account' title='Bestowal_Facebook' />
                </a>
                <a href="https://x.com/bestowalsystems?t=tUhwfo5zEXhU5SON5Ice2g&s=09" className="facebook" target="_blank" rel="noopener noreferrer">
                <img src={twitter} className='social-icon' alt='Bestowal_X_Account' title='Bestowal_X'/>
                </a>
                <a href="https://www.instagram.com/bestowalsystems?igsh=MWY1ODV5Mmc4am02aA==" className="twitter" target="_blank" rel="noopener noreferrer">
                <img src={Instagram} className='social-icon' alt='Bestowal_Instagram_Account' title='Bestowal_Instagram'/>
                </a>
                <a href="https://youtube.com/@bestowalsystems?si=JwmKOLGIGSFmTbk5" className="instagram" target="_blank" rel="noopener noreferrer">
                <img src={YT} className='social-icon' alt='Bestowal_YouTube_Account' title='Bestowal_Youtube'/>
                </a>
                <a href="https://pin.it/5aaQo7dzK" className="youtube" target="_blank" rel="noopener noreferrer">
                <img src={pinterest} className='social-icon' alt='Bestowal_pinterest_Account' title='Bestowal_Pinterest'/>
                </a>
                <a href="https://www.reddit.com/user/bestowalsystems/" className="reddit" target="_blank" rel="noopener noreferrer">
                  <img src={reddit} className='social-icon' alt='Bestowal_reddit_Account' title='Bestowal_reddit'/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div className="container-fluid">
      <div className="row footer-bottom">
        <div className="col text-center">
          <p>
            Copyright © 2024 Bestowal System & Services. 
            <a href="https://www.bestowalsystems.com"> www.bestowalsystems.com </a> | All Rights Reserved.
          </p>
        </div>
        <div className="col text-right">
         
          <button className="btn btn-primary scroll-top-btn" onClick={scrollToTop} alt="toggle_up_Arrow" title="toggle UP">
          <span class="material-symbols-outlined"  style={{ display: 'inline-block', transform: 'rotate(-90deg)',margin: '0px' }} >
east
</span>
          </button>
 
        </div>
      </div>
    </div>
  </footer>
  );
};

export default Footer;
