/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import XYZ1 from "./Images/XYZ1.png";
import XYZ2 from "./Images/XYZ2.png";
import XYZ3 from "./Images/XYZ3.png";
import XYZ4 from "./Images/XYZ4.png";
import XYZ5 from "./Images/XYZ5.png";
const Blogdetails1 = () => {
  return (
    <>
      <div className="container blogdetails">
        <div className="article-header mt-5">
          <span class="badge bg-primary">SAP</span>
          <h1>ABC / XYZ Segmentation – K - Means Algorithm</h1>
          <div className="bloginfo">
            <p>
              <strong>Author Name</strong>:BCS
            </p>
            <p>Publish Date: September 11, 2024</p>
          </div>
        </div>
        <div className=" article-content mt-4">
          <p>
            How the Segmentation varies as compared to K means and other
            algorithms
          </p>
          <br />
          <p>
            <b>Introduction:</b>
          </p>
          <br />
          <p>
            ABC/XYZ segmentation is the method of grouping planning objects
            based on the values of a selected key figure.
            <br />
            These two types of segmentation serve distinct purposes.
            <br />
            ABC segmentation involves prioritizing planning objects based on
            their relative importance. For instance, you might categorize
            combinations of products and customers based on the revenue key
            figure.
            <br />
            On the other hand, XYZ segmentation involves classifying planning
            objects based on their demand volatility.
            <br />
            The purpose of ABC analysis is to categorize planning objects based
            on their level of importance or value. This analysis assesses each
            object using the following indicators:
          </p>
          <br />
          <p>
            <b>
              A – Represents the highest value or the specified number of
              objects that generate the greatest value.
            </b>
          </p>
          <p>
            <b>
              B – Represents a lower value or the specified number of objects
              that generate less value.
            </b>
          </p>
          <p>
            <b>
              C – Represents the lowest value or the specified number of objects
              that generate the least value.
            </b>
          </p>
          <br />
          <p>
            XYZ analysis is utilized to categorize planning objects based on the
            level of variance in a specific coefficient. Throughout the process
            of XYZ analysis, each object is assigned to one of the following
            indicators:
          </p>
          <br />
          <p>
            <b>X – Minimal variation</b>
          </p>
          <br />
          <p>
            <b>Y – Moderate variation</b>
          </p>
          <br />
          <p>
            <b>Z – Maximum variation.</b>
          </p>
          <br />
          <p>
            <b>Scenario 1: By Pareto principle (Sorted and cumulated %)</b>
          </p>
          <br />
          <p>
            This approach relies on applying the Pareto principle to a
            collection of planning elements within a designated timeframe. The
            Pareto principle, also known as the 80/20 rule, suggests that a
            significant portion of the outcomes (approximately 80%) can be
            attributed to a small fraction of the factors (around 20%).
          </p>
          <img src={XYZ1} />
          <br />
          <p>Results will appear in Product Master Data.</p>
          <img src={XYZ2} />
          <br />
          <p>
            <b>
              Now, just make a comparison between the Pareto principle and K
              -means Algorithm, and note down the products that are coming under
              the ‘A’ category.
            </b>
          </p>
          <br />
          <img src={XYZ3} />
          <br />
          <p>
            <b>Scenario 2: K- Means Algorithm:</b>
          </p>
          <br />
          <p>
            <b>
              This algorithm uses machine learning to create segments. This is
              useful when if you are not sure about how much threshold can be
              given.
            </b>
          </p>
          <br />
          <p>
            The initial step involves the system randomly assigning planning
            objects to the defined segments. It then calculates the variance by
            comparing the total value of each planning object with the mean
            value of the segment it belongs to. Subsequently, the system
            rearranges the segments and reevaluates the variances. This
            iterative process continues until the values cannot get any closer
            to the means of the segments, which are referred to as centers. The
            segment with the highest center value is designated as segment A,
            the one with the second highest center value becomes segment B, and
            so forth.
          </p>
          <br />
          <p>
            <b>Create ABC/XYZ Segmentation profile using K- means algorithm:</b>
          </p>
          <img src={XYZ4} />
          <br />
          <p>
            <b>
              After Scheduling the profile, we can see the results in "Product
              Master Data"
            </b>
          </p>
          <img src={XYZ5} />
          <br />
          <p>
            The results derived from the Pareto and K means algorithms
            demonstrate significant disparities upon comparison. In particular,
            the K means algorithm exclusively assigns a single product to the
            'A' category.
            <br />
            By referring to the application log, valuable insights can be
            obtained regarding the maximum and average distances between the
            center and the total sales values within each segment. If these
            distances are excessively large, it indicates that the segments may
            lack homogeneity. In such cases, it might be necessary to define
            additional segments to enhance the level of homogeneity within each
            segment.
            <br />
            It is important to note that the system may assign planning objects
            to a smaller number of segments than what was initially defined in
            the profile. This occurs when the number of distinct values
            justifies such an allocation
          </p>
          <br />

          <p>
            <b>Conclusion</b>
          </p>
          <br />
          <p>
            K-Means cluster offers a significant benefit in that the flexibility
            allows for easy adjustment of the required metrics according to the
            evolving business needs as the company expands. Moreover, K-Means
            effortlessly adapts to new data sets with no need to pre-define
            thresholds, as the algorithm automatically identifies them.
          </p>
        </div>
      </div>
    </>
  );
};

export default Blogdetails1;
