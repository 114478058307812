/* eslint-disable jsx-a11y/alt-text */

import React, { useState } from "react";
import illustration1 from "./Images/illustration1.png";
import logo11 from "./Images/arrow_outward.png";
import { Image } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import WWW from "./Images/WWW.png";
import Rectangle from "./Images/Rectangle.png";
import Note from "./Images/Note.png";
import PC from "./Images/PC.png";
import pay from "./Images/pay.png";
import search from "./Images/search.png";
import search1 from "./Images/search1.png";
import lamp from "./Images/lamp.png";
import curve from "./Images/curve.png";
import goal from "./Images/goal.png";
import rect1 from "./Images/Rectangle1.png";
import rect2 from "./Images/Rectangle2.png";
import rect3 from "./Images/Rectangle3.png";
import rect4 from "./Images/Rectangle4.png";
import rect5 from "./Images/Rectangle5.png";
import rect6 from "./Images/Rectangle6.png";
import Digital from "./Images/Digital.jpg";

const Digital_Marketing = () => {

  



 
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <div className="container digital">
        <div className="row digitalrow">
          <div className="col-md-4">
            <img src={illustration1} className="digitalimg" height={400} />
          </div>
          <div className="col-md-8">
            <h2>
              We Are Digital Marketing <span>Expert</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid nativecolor">
        <div className="container digital1">
          <h2>Own Your Online Presence: Grow Sales</h2>
          <p>
            Bestowal Systems & Services is a dynamic digital marketing agency
            renowned for its strategic approach and proven expertise in driving
            online success for small businesses. We excel in creating tailored
            digital strategies that encompass SEO, content marketing, social
            media management, and comprehensive analytics. Our team of
            specialists is dedicated to maximizing client ROI by implementing
            data-driven strategies that resonate with target audiences and
            elevate brand visibility. Unlike cookie-cutter solutions, we
            prioritize personalized client partnerships, ensuring that every
            campaign is meticulously crafted to meet unique business.
            {!isExpanded ? (
              <span className="dots">...</span>
            ) : (
              <span className="more">
                objectives With a commitment to transparency and measurable
                results, we empower businesses to thrive in the digital
                landscape through continuous optimization and adaptation to
                industry trends. Whether it's enhancing online presence,
                boosting conversions, or scaling growth, Bestowal Systems &
                Services delivers impactful digital marketing solutions that
                drive lasting success.
              </span>
            )}
          </p>
          <button className="sapbtn" onClick={toggleExpand}>
            {isExpanded ? "Read Less" : "Read More"}
            <Image src={logo11} className="img0" alt="Read more icon" />
          </button>
        </div>
      </div>
      
      <div className="container native">
        <h2>Digital Marketing Road Map</h2>
        <div className="row angularframe">
          <div className="angularcontent">
            <span className="angularspan">
              <img src={rect6} className="d11" />
            </span>
            <h3>Campaign Manitoring</h3>
          </div>
          <div className="angularcontent">
            <span className="angularspan">
              <img src={rect5} className="d11" />{" "}
            </span>
            <h3>Competitive Pricing</h3>
          </div>
        </div>
        <div className="row angularframe1">
          <div className="angularcontent">
            <span className="angularspan">
              <img src={rect1} className="d11" />
            </span>
            <h3>Data-driven Digital Marketing</h3>
          </div>
          <div className="angularcontent">
            <img src={Digital} width={200} alt="Angular 4" />
          </div>
          <div className="angularcontent">
            <span className="angularspan">
              <img src={rect4} className="d11" />
            </span>
            <h3>Omnichannel Personalization</h3>
          </div>
        </div>
        <div className="row angularframe">
          <div className="angularcontent">
            <span className="angularspan">
              <img src={rect2} className="d11" />
            </span>
            <h3>Digital Marketing Framework</h3>
          </div>
          <div className="angularcontent">
            <span className="angularspan">
              <img src={rect3} className="d11" />
            </span>
            <h3>Industry Experts</h3>
          </div>
        </div>
      </div>
      <div className="container digital">
        <div className="row">
          <div className="col-md-4">
            <Card>
              <span className="digitalspan">
                {" "}
                <Card.Img src={search} />
              </span>
              <Card.Body>
                <Card.Title>Search Engine Optimization (SEO)</Card.Title>
                <Card.Text>
                  Unlock higher organic rankings and search visibility with our
                  data-driven approach to SEO. Our internet marketing agency
                  utilizes in-depth keyword research, strategic on-page and
                  off-page optimization, and leverages Google Search Console to
                  track progress.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <span className="digitalspan">
                {" "}
                <Card.Img src={WWW} />
              </span>
              <Card.Body>
                <Card.Title>Web Development (SEO)</Card.Title>
                <Card.Text>
                  Unlock higher organic rankings and search visibility with our
                  data-driven approach to SEO. Our internet marketing agency
                  utilizes in-depth keyword research, strategic on-page and
                  off-page optimization, and leverages Google Search Console to
                  track progress.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <span className="digitalspan">
                {" "}
                <Card.Img src={Rectangle} />
              </span>
              <Card.Body>
                <Card.Title>Social Media Marketing</Card.Title>
                <Card.Text>
                  Unlock higher organic rankings and search visibility with our
                  data-driven approach to SEO. Our internet marketing agency
                  utilizes in-depth keyword research, strategic on-page and
                  off-page optimization, and leverages Google Search Console to
                  track progress.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <span className="digitalspan">
                {" "}
                <Card.Img src={Note} />
              </span>
              <Card.Body>
                <Card.Title>Content Writing</Card.Title>
                <Card.Text>
                  Unlock higher organic rankings and search visibility with our
                  data-driven approach to SEO. Our internet marketing agency
                  utilizes in-depth keyword research, strategic on-page and
                  off-page optimization, and leverages Google Search Console to
                  track progress.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <span className="digitalspan">
                {" "}
                <Card.Img src={PC} />
              </span>
              <Card.Body>
                <Card.Title>Email Marketing</Card.Title>
                <Card.Text>
                  Unlock higher organic rankings and search visibility with our
                  data-driven approach to SEO. Our internet marketing agency
                  utilizes in-depth keyword research, strategic on-page and
                  off-page optimization, and leverages Google Search Console to
                  track progress.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <span className="digitalspan">
                {" "}
                <Card.Img src={pay} />
              </span>
              <Card.Body>
                <Card.Title>Pay Per Click (PPC)</Card.Title>
                <Card.Text>
                  Unlock higher organic rankings and search visibility with our
                  data-driven approach to SEO. Our internet marketing agency
                  utilizes in-depth keyword research, strategic on-page and
                  off-page optimization, and leverages Google Search Console to
                  track progress.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <div className="fluid-container dgcolor">
        <div className="container process">
          <h2>Navigate Our Work Process</h2>

          <div className="processframe">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={search1} className="d111" />
              </span>
              <h4>Research</h4>
            </div>
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={lamp} className="d111" />
              </span>
              <h4>Find Ideas</h4>
            </div>
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={curve} className="d111" />
              </span>
              <h4>Optimization</h4>
            </div>
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={goal} className="d111" />
              </span>
              <h4>Reach Target</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Digital_Marketing;
