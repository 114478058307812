import React from "react";
import { FaLocationArrow, FaCalendarDay, FaBriefcase,FaTasks } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Contact from "./Images/Carrer.jpg"; // Adjust the path as necessary
import { Link } from "react-router-dom";
const Carrier = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const handleApply = (jobTitle) => {
    const formattedTitle = jobTitle.replace(/\s+/g, '-').toLowerCase();
    navigate(`/apply/${formattedTitle}`, { state: { job: { title: jobTitle } } });
  };

  return (
    <>
      <div className="carrier-container">
        <div className="carrierimg"></div>
        <div className="carrier-text">Career at Bestowal</div>
      </div>
      <div className="container aboutcarrier">
        <div className="row carrierrow">
          <div className="col-md-6">
            <img src={Contact} alt="Contact" />
          </div>
          <div className="col-md-6">
            <h2>Great Opportunity for Your Inspiring Talent</h2>
            <p>
              Looking to take your career to the next level? Bestowal is
              offering a fantastic opportunity to work with global brands and
              accelerate your career path. If you are a passionate learner with
              solid coding skills and an interest in the latest technologies,
              you'll find a welcoming home at Bestowal.<br /> At Bestowal, we pride
              ourselves on providing an excellent learning environment for our
              consultants, with ample opportunities to continuously update their
              skills and knowledge on the newest and most advanced technologies.
              Our team is dedicated to helping you grow and achieve your full
              potential, both personally and professionally.<br /> With Bestowal,
              you'll be able to work on exciting projects that will challenge
              and inspire you. You'll collaborate with talented and experienced
              professionals who share your passion for technology. Whether
              you're a seasoned expert or just starting out in your career, we
              believe that Bestowal is the perfect place to realize your
              ambitions and achieve your goals.
            </p>
          </div>
        </div>
      </div>
      <h1 className="jobs">Job Openings</h1>
      <div className="container product-list12">
        <div className="row">
          {/* Job Card 1 */}
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>SAP ABAP Developer</h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 4-10 years</p><hr/>
                <p><FaTasks /> Role: SAP ABAP, EWM</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>SAP ABAP Technical</h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 3-7 years</p><hr/>
                <p><FaTasks /> SAP ABAP</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>SAP ABAP Consultant</h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 5-10 years</p><hr/>
                <p><FaTasks /> SAP ABAP, MM, SD,S4</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
         
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>SAP Basis(Level 3)
                </h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 7-12 years</p><hr/>
                <p><FaTasks />SAP Basic</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>SAP ABAP Workflow</h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 4-10 years</p><hr/>
                <p><FaTasks />SAP Workflow</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>SAP ISU Module</h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 3-7 years</p><hr/>
                <p><FaTasks />SAP ABAP on HANA CDS</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>SAP FI for GFDP</h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 10-15 years</p><hr/>
                <p><FaTasks /> FI, GFDP</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>ReactJS Developer</h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 0-1 years</p><hr/>
                <p><FaTasks />ReactJS, Redux, RestAPI</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>React Native Developer</h3><hr/>
                <p><FaLocationArrow /> Pune</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 5-10 years</p><hr/>
                <p><FaTasks />React Native</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>NodeJS Developer
                </h3><hr/>
                <p><FaLocationArrow /> Pune</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 8-12 years</p><hr/>
                <p><FaTasks />NodeJS</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>Java Developer</h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 4-10 years</p><hr/>
                <p><FaTasks /> Core Java, Spring, Hibernate, Boostrap</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>Full Stack Developer
                </h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 3-7 years</p><hr/>
                <p><FaTasks />Java, Spring, Hibernate</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>Selenium Tester
                </h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 5-12 years</p><hr/>
                <p><FaTasks />Selenium Testing, Automation Testing</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>Test Lead</h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 5-9 years</p><hr/>
                <p><FaTasks />Testing</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>Automation Tester</h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 2-7 years</p><hr/>
                <p><FaTasks />Mobile Application Testing</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>Manual Tester</h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 3-7 years</p><hr/>
                <p><FaTasks />Manual Testing</p><hr/>
                <Link to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>HR Recruritement</h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 0-1 years</p><hr/>
                <p><FaTasks />Recruitment, HR-Executive</p><hr/>
                <Link  to="/Modal" className="btn customs-button">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <div className="job-card">
              <div className="job-card-details">
                <h3>Senior Informatica
                </h3><hr/>
                <p><FaLocationArrow /> Pune/Remote</p><hr/>
                <p><FaBriefcase /> Full Time And Freelancing</p><hr/>
                <p><FaCalendarDay /> 4-10 years</p><hr/>
                <p><FaTasks />IICS</p><hr/>
                <Link className="btn customs-button" to="/Modal">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Carrier;
