/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Java1 from "./Images/javadev.png";
import { useState } from "react";
const Java = () => {
  const breakpoints = [
    { width: 2, itemsToShow: 1 },
    { width: 530, itemsToShow: 2 },
    { width: 760, itemsToShow: 3 },
    // { width: 1200, itemsToShow: 4 },
  ];
    const [isExpanded, setIsExpanded] = useState(false);
  
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };
  return (
    <>
      <div className="container java">
        <div className="row javarow">
          <div className="col-md-5">
            <img src={Java1}/>
          </div>
          <div className="col-md-7">
            <h2>Java Development</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid javacolor">
        <div className="container java1">
          <h2>Our Principles, Your Satisfaction</h2>
          <p>
            Our expertise at Bestowal Systems & Services is very skilled in the
            creation of Java applications. We employ the most up-to-date
            contemporary Java-related technology to assist clients in quickly
            and efficiently creating long-lasting Java-based software. Bestowal
            creates and implements best practises for java development,
            including flexibility on the user interface, performance tuning,
            debugging, and potential web services. These procedures, which have
            been adhered to by our java web development firm, have guaranteed
            successful completion records without any additional costs or
            overruns.Our Java programming service has a remarkable track record
            of developing simple to complicated solutions to meet the needs of
            customers throughout the world. Our skill set enables clients
            {!isExpanded ? (
              <span className="dots">...</span>
            ) : (
              <span className="more">
 to enjoy the full potential of the apps with a greater return on
            investment by utilizing best practises and tried-and-true
            approaches. The application development solutions we create are
            simple to upgrade, which is a significant benefit of working with
            us. The expert pool of professionals blended with capabilities
            ensure that projects are delivered and yield better results.              </span>
            )}
          </p>
          <button className="sapbtn" onClick={() => toggleExpand(0)}>
            {isExpanded ? "Read Less" : "Read More"}
            <span className="material-symbols-outlined">east</span>
          </button>
        </div>
      </div>
      <div className='container native'>
        <h2>We Streamline Your Business With Java Development Services</h2>
      <div className="row javarow">
          <div className="col-md-4">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <h1>1</h1>
                  <h3>Java Web Development Services</h3>
                </div>
                <div class="flip-card-back">
                  <p>
                  Our team of Java developers is well-versed in Java web architecture and its engineering ecosystem, making them well-equipped to provide cutting-edge Java software development services.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <h1>2</h1>
                  <h3>Custom Java Development</h3>
                </div>
                <div class="flip-card-back">
                  <p>
                  Our team of Java specialists, including UI/UX designers, developers, and QA testers, will create exceptional Java applications tailored to your requirements. They are adept at developing complex banking systems, ERPs, large-scale enterprise applications, and other software solutions that require high-end security.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <h1>3</h1>
                  <h3>Java Application & Maintenance Support</h3>
                </div>
                <div class="flip-card-back">
                  <p>
                  At Bestowal, our professional Java engineers help you set up and run your Java app seamlessly. We offer tailored support and maintenance services to ensure your app's agility, performance, and compatibility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <div className="container javabenifits">
        <h2>Top Benefits Of Our Java Development Services:</h2>
        <div className="row androidrow1">
          <div className="col-md-6">
            <div className="javainfo1">
              <div className="subinfo1">
                <h2>360-Degree Solutions</h2>
                <p>
                Our comprehensive Java development services cover every aspect of the software development lifecycle, ensuring a robust and exceptional outcome for your business needs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="javainfo1">
              <div className="subinfo1">
                <h2>Reliability and Transparency</h2>
                <p>
                We believe in maintaining transparency with our clients. You will always be aware of the strategies and technologies we are implementing, ensuring you fully trust our process.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="javainfo1">
              <div className="subinfo1">
                <h2>Enhance ROI and Sales</h2>
                <p>
                Our Java development services are designed to optimize your business processes, ultimately boosting your sales and return on investment through efficient and scalable solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="javainfo1">
              <div className="subinfo1">
                <h2>Custom Solutions</h2>
                <p>
                We tailor our Java development services to meet the specific needs of your business, whether it's for enterprise-level applications, e-commerce platforms, or custom software.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="javainfo1">
              <div className="subinfo1">
                <h2>Performance Evaluation</h2>
                <p>
                We provide regular performance evaluations and reports, keeping you informed about your project's progress and ensuring it meets the highest standards.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="javainfo1">
              <div className="subinfo1">
                <h2>Scalability</h2>
                <p>
                Our solutions are built to scale with your business, allowing for easy updates and expansions as your business grows.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="javainfo1">
              <div className="subinfo1">
                <h2>Security</h2>
                <p>
                We prioritize security in all our Java applications, ensuring that your data and operations are protected against potential threats.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="javainfo1">
              <div className="subinfo1">
                <h2>Expert Team</h2>
                <p>
                Our team of experienced Java developers, UI/UX designers, and QA testers work together to deliver top-notch solutions tailored to your requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Java;
