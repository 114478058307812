/* eslint-disable jsx-a11y/alt-text */

import React, { useState } from "react";
import AndroidImage from "./Images/AndroidImg.png"; // Renamed to avoid naming conflict
import circle from "./Images/check_circle.png";
import java from "./Images/JAVA.png";
import koltin from "./Images/kotlin.png";
import Android1 from "./Images/Android.png";
import sketch from "./Images/sketch.png";
import photoshop from "./Images/photoshop.png";
import figma from "./Images/figma.png";
import marvel from "./Images/marvelapp.png";
import gennymotion from "./Images/genymotion.png";
import flutter from "./Images/Flutter.png";
import firebase from "./Images/Firebase.png";
import realme from "./Images/realmio.png";
import googleplay from "./Images/Googleplay.png";
import mysql from "./Images/mysql.png";
import glide from "./Images/glide.png";
import Modal1 from "./Modal1";
import ipad from "./Images/ipad.png";
import androidapp from "./Images/androidapp.jpg";
const Android = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-6">
            <img src={androidapp} alt="Android" />
          </div>
          <div className="col-md-6">
            <h2>Android Development</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid nativecolor">
        <div className="container native">
          <h2>Understanding Android Development</h2>
          <p>
            Organizations all over the world are swiftly embracing React Native,
            one of the most well-liked programming languages, to develop
            enticing, feature-rich, native user experiences, and cross-platform
            apps. If you want to create apps that will change the game for both
            Android and iOS, React Native Development is the way to go. React
            Native is a framework that lets developers build native mobile apps
            using JS. It enables you to build an application for multiple
            platforms while using the same codebase. This can shorten the
            development time of your project and reduce the overall cost of
            building a mobile app. Another advantage is that you don’t need two
            separate teams for native development, but rather the services of a
            single React Native team.                 We have huge expertise in hybrid mobile app
            {!isExpanded ? (
              <span className="dots">.....</span>
            ) : (
              <span className="more">
          development and years  of experience in the industry. We have gained enough knowledge in providing mobile app development services which are compatible with different platforms. Hybrid app development is one of the most important mobile app solutions that helps position a company name in the mobile industry.
              </span>
            )}
          </p>
          <button className="sapbtn" onClick={() => toggleExpand(0)}>
            {isExpanded ? "Read Less" : "Read More"}
            <span className="material-symbols-outlined">east</span>
          </button>
          
        </div>
      </div>
      <div className="container android">
    <h2>Built Powerfull Apps With Us</h2>
    <div className="row androidrow">
      <div className="col-md-6">
        <div className="androidframe">
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>Quality</h4>
            </div>
            <p>
            We perform a thorough quality check and assessment process for our apps. Our quality assurance includes rigorous testing across various devices and environments to ensure consistent performance and user satisfaction.</p>
          </div>
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>Upgrades</h4>
            </div>
            <p>
            We stay updated with the latest Android platform updates to incorporate new features and security enhancements into your applications. Our proactive approach to upgrades ensures your apps remain compatible with new device releases and operating system versions.
            </p>
          </div>
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>Confidentiality</h4>
            </div>
            <p>All our projects are covered under non-disclosure agreements, ensuring strict confidentiality of your intellectual property and business processes.
            We adhere to industry best practices and legal standards to safeguard your sensitive information and maintain client trust.</p>            </div>
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>Scalability</h4>
            </div>
            <p>Extend your application's functionalities seamlessly as your user base and business requirements grow.
         We design scalable architectures that support future expansion without compromising performance or user experience.</p>
          </div>
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>Cost-Effective</h4>
            </div>
            <p>Our development approach ensures cost-effectiveness throughout the project lifecycle, maximizing your return on investment.
           We provide transparent pricing and efficient resource utilization to minimize unnecessary expenses and deliver value.</p>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <img src={ipad}/>
        <img src={AndroidImage} alt="Android" />
      </div>
    </div>
    <button onClick={handleOpenModal}>
      Get In Touch <span className="material-symbols-outlined">east</span>
    </button>
    {isModalOpen && <Modal1 onClose={handleCloseModal} />}
  </div>
      <div className="container android">
          <div className="row androidrow1">
            <div className="col-md-4">
              <div className="nodeinfo1">
                <div className="subinfo1">
                  <h2>Custom Android Apps Development</h2>
                  <p>
                    Our Android developers are adept at fulfilling the diverse set of business requirements. They have brilliantly developed numerous custom Android apps for a mix of industry verticals in the least possible time. They know the art of developing applications that shine and stand apart from others.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="nodeinfo1">
                <div className="subinfo1">
                  <h2>Android Web-based Apps</h2>
                  <p>
                    We are proficient in developing easy to install, maintain and highly secure Android web-based applications. By choosing our Android-web based development services, you can get a flexible application that will expand easily as your business grows.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="nodeinfo1">
                <div className="subinfo1">
                  <h2>Android App Porting</h2>
                  <p>
                    We offer a comprehensive porting service for your Android app with the latest industry standards and features. With this service, our ultimate goal is to build your effective presence on various devices and OS through which you can garner more clients and elevate your business growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row androidrow1">
            <div className="col-md-4">
              <div className="nodeinfo1">
                <div className="subinfo1">
                  <h2>Android UI/UX Design</h2>
                  <p>
                    We are focused on creating powerful and reliable Android devices that render an amazing user experience. Our expert designers make diligent use of the functionality and skillfulness of the Android platform to craft alluring designs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="nodeinfo1">
                <div className="subinfo1">
                  <h2>Android App Testing</h2>
                  <p>
                    To make your app market-ready, we perform vigorous testing to check for any bugs that can halt the operation. Our team of quality inspectors works relentlessly to make your Android app run without a glitch and you can get your hands on a highly-functional app.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="nodeinfo1">
                <div className="subinfo1">
                  <h2>Android App Consultation</h2>
                  <p>
                    We will be there with you from start to finish as your Android app development company. If you have an app idea in mind, then feel free to share it with us. Our developers carefully analyze your requirements and then kick start the Android development process.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="androidstack1">
            <h2>Our Tech Stack</h2>
            <div className="androidstack">
              <img src={java} width={110} alt="Java" />
              <img src={koltin} width={110} alt="Kotlin" />
              <img src={Android1} width={110} alt="Android" />
              <img src={sketch} width={110} alt="Sketch" />
              <img src={photoshop} width={110} alt="Photoshop" />
              <img src={figma} width={110} alt="Figma" />
              <img src={marvel} width={110} alt="Marvel" />
              <img src={gennymotion} width={110} alt="Genymotion" />
              <img src={flutter} width={110} alt="Flutter" />
              <img src={firebase} width={110} alt="Firebase" />
              <img src={realme} width={110}  alt="Realm" />
              <img src={googleplay} width={110} alt="Google Play" />
              <img src={mysql} width={110} alt="MySQL" />
              <img src={glide} width={110} alt="Glide" />
            </div>
          </div>
        </div>
    </>
  );
};

export default Android;