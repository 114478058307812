
import BCS40 from "./Images/BCS40.jpg";
import BCS11 from "./Images/BCS11.jpg";
import BCS39 from "./Images/BCS39.jpg";
import BCS42 from "./Images/BCS42.jpg";
import BCS10 from "./Images/BCS10.jpg";
import BCS50 from "./Images/BCS50.jpg";
import BCS13 from "./Images/BCS13.jpg";
import BCS51 from "./Images/BCS51.jpg";
const life1 = [
  {
    itemtoshow: 1,
    image: BCS40,
  },
  {
    itemtoshow: 2,
    image: BCS13,
  },
  {
    itemtoshow: 3,
    image: BCS10,
  },
  {
    itemtoshow: 5,
    image: BCS39,
  },
  {
    itemtoshow: 6,
    image: BCS42,
  },
  {
    itemtoshow: 7,
    image: BCS11,
  },
  {
    itemtoshow: 8,
    image: BCS50,
  },
  // {
  //   itemtoshow: 9,
  //   image: BCS12,
  // },
  {
    itemtoshow: 9,
    image: BCS51,
  },
];

export default life1;