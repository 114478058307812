/* eslint-disable jsx-a11y/alt-text */


import React, { useState } from 'react';
import life1 from "./life1";
import BCS2 from "./Images/BCS2.jpg"
import Carousel from "@itseasy21/react-elastic-carousel";
import Modal from 'react-modal';
import BCS_bd from "./Images/BCS_bd.jpg";
import BCS44 from "./Images/BCS44.jpeg";
import BCS40 from "./Images/BCS40.jpg";
import BCSdussera from "./Images/BCSdussera.jpg";
import BCS41 from "./Images/BCS41.jpg";
import BCS42 from "./Images/BCS42.jpg";
import BCSalibag from "./Images/BCSalibag.jpg";
import BCS12 from "./Images/BCS12.jpeg";
import BCS_blooddon from "./Images/BCS_blooddon.jpeg";
import BCS_blooddonn from "./Images/BCS_blooddonn.jpeg";
import BCS_blood from "./Images/BCS_blood.jpeg";
import BCS_shiv1 from "./Images/BCS_shiv1.jpeg";
import BCS_shiv3 from "./Images/BCS_shiv3.jpeg";
import BCS_shiv4 from "./Images/BCS_shiv4.jpeg";
import BCS_duss from "./Images/BCS_duss.jpeg";
import BCS_work from "./Images/BCS_work.jpeg";
import BCSrepub from "./Images/BCSrepub.jpeg";
import BCSrepub2 from "./Images/BCSrepub2.jpeg";
import BCS_raigad from "./Images/BCS_raigad.jpeg";
import BCS_raigad1 from "./Images/BCS_raigad1.jpeg";
import BCS_women from "./Images/BCS_women.jpeg";
import BCS_women1 from "./Images/BCS_women1.jpeg";
import BCS_women2 from "./Images/BCS_women2.jpeg";
import BCS_workk from "./Images/BCS_workk.jpeg";
import BCS_trad from "./Images/BCS_trad.jpeg";
import BCS_tradd from "./Images/BCS_tradd.jpeg";
import BCS_alibag from "./Images/BCS_alibag.jpeg";
import BCS_alibag1 from "./Images/BCS_alibag1.jpeg";
import BCS_repubbb from "./Images/BCS_repubbb.jpeg";
import Dusshera from "./Images/Dusshera.jpg"
// import Republic from "./Images/RepublicDay.jpg";
import Workspace from "./Images/Workspace.jpg";
import Workspace1 from "./Images/Workspace1.jpg";
// import Raigad from "./Images/Raigad1.jpeg";
import "./App.css"; 

const breakpoints = [
  { width: 2, itemsToShow: 1 },
  { width: 530, itemsToShow: 1 },
  { width: 760, itemsToShow: 1 },
  { width: 1200, itemsToShow: 1 },
];

const images = [
  { src: BCS_bd, text: "Blood donation camp at BCS", category: "Category 1" },
  { src: BCS44, text: "Shiv Jayanti Celebrations",category: "Category 2" },
  { src: BCS_duss,text: "Traditional Day Celebration",category: "Category 3" },
  { src: BCS_workk, text: "Workspace at BCS",category: "Category 4" },
  { src: BCS_raigad, text: "Raigad Fort Trip",category: "Category 5" },
  { src: BCSrepub, text: "Republic Day Celebrations ",category: "Category 6" },
  { src: BCS41, text: "Women's Day Celebration",category: "Category 7" },
  { src: BCS12, text: "Guru purnima Celebration",category: "Category 8" },
  { src: BCSalibag, text: "Alibaug Trip",category: "Category 9" },
];

const categoryImages = {
  "Category 1": [
    { src: BCS_bd},
    { src: BCS_blood},
    { src: BCS_blooddon},
    { src: BCS_blooddonn}
    // { src: "./Images/BCS.jpg", text: "Additional Image 1 for Category 1" },
    // { src: "./Images/BCS37.jpg", text: "Additional Image 2 for Category 1" },
  ],
  "Category 2": [
    { src: BCS44 },
    { src: BCS_shiv4},
    { src: BCS_shiv3},
    { src: BCS_shiv1}
    // { src: "./Images/BCS38.jpg", text: "Additional Image 1 for Category 2" },
    // { src: "./Images/BCS39.jpg", text: "Additional Image 2 for Category 2" },
  ],
  "Category 3": [
    { src: BCS_duss},
    { src: BCSdussera},
    {src:Dusshera}
    // { src: "./Images/BCS40.jpg", text: "Additional Image 1 for Category 3" },
    // { src: "./Images/BCS41.jpg", text: "Additional Image 2 for Category 3" },
  ],
  "Category 4": [
    {src: BCS_workk},
    { src: BCS12 },
    { src: BCS_work},
    {src: Workspace},
    {src:Workspace1}    // { src: "./Images/BCS42.jpg", text: "Additional Image 1 for Category 4" },
  ],
  "Category 5": [
    {src: BCS_raigad},
    { src: BCS42},
    { src: BCS_raigad1 },
    // { src: "./Images/BCS42.jpg", text: "Additional Image 1 for Category 4" },
  ],
  "Category 6": [
    {src: BCSrepub},
    { src: BCSrepub2 },
    { src: BCS_repubbb},
    // { src: "./Images/BCS42.jpg", text: "Additional Image 1 for Category 4" },
  ],
  "Category 7": [
    {src:BCS41},
    { src: BCS_women},
    { src: BCS_women1 },
    { src: BCS_women2},
    // { src: "./Images/BCS42.jpg", text: "Additional Image 1 for Category 4" },
  ],
  "Category 8": [
    { src: BCS12 },
    {src:BCS_trad}
  
    // { src: "./Images/BCS42.jpg", text: "Additional Image 1 for Category 4" },
  ],
  "Category 9": [
    {src:BCSalibag},
    { src: BCS40},
    { src: BCS_alibag },
    { src: BCS_alibag1 },
    // { src: "./Images/BCS42.jpg", text: "Additional Image 1 for Category 4" },
  ],
};

const Life = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index, category) => {
    setCurrentImages(categoryImages[category]);
    setCurrentImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImages([]);
  };

  return (
    <>
    <div className="container about">
        <div className="row aboutusrow">
           <div className="col-md-6">
            <img src={BCS2} />
          </div>
           <div className="col-md-6">
            <h2>Life at Bestowal</h2>
            <p>
              At Bestowal, we believe that a workplace should be welcoming,
            engaging, and filled with opportunities for meaningful work. Our
             benefits; we offer growth opportunities, recognize excellence with
              awards, and foster a sense of community through social activities.
              We strive to make Bestowal a place where innovation thrives, ideas
              are celebrated, and colleagues become lifelong friends. Our
           vibrant culture encourages personal and professional development,
              ensuring every team member feels valued and motivated. From the
              moment you join, you’ll experience the supportive and dynamic
               environment that defines Bestowal. Whether through collaborative
              projects, learning opportunities, or our inclusive company events,
               you’ll find a community that champions your aspirations.
             </p>
         </div>
         </div>
       </div>
      <div className='fluid-container Life'>
        <div className='container life'>
          <h2>Life At Bestowal</h2>
          <p>Welcome to a company where new ideas are celebrated, learning opportunities are abundant, people are treated as individuals and colleagues become lifelong connections.</p>
       
        <Carousel breakPoints={breakpoints}>
          {life1.map((item, i) => (
            <div key={i} className="crdimg">
              <img src={item.image} className="crdimg" alt={`carousel ${i}`} />
            </div>
          ))}
        </Carousel>
        </div>
      </div>
      <div className='container life1'>
      <h4>Our people are the driving force and the foundation on which we have grown to be the successful organization that we are today.</h4>
        <div className='grid-container'>
          {images.map((image, index) => (
            <div key={index} className={`grid-item ${index === 3 ? 'middle-item' : ''}`}>
              <div className='lifecard' onClick={() => openModal(index, image.category)}>
                <img src={image.src} className='lifeimg' alt={`life ${index}`} />
                <p className='lifeimg-text'>{image.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Carousel"
        className="Modal"
        overlayClassName="Overlay"
      >
        <button onClick={closeModal} className="close-button">x</button>
        <Carousel initialActiveIndex={currentImageIndex}>
          {currentImages.map((image, index) => (
            <div key={index} className="carousel-image">
              <img src={image.src} alt={`carousel ${index}`} />
              <p className="carousel-text">{image.text}</p>
            </div>
          ))}
        </Carousel>
      </Modal>
    </>
  );
};
export default Life;