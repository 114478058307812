/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import node from "./Images/Node.png";
import logo11 from "./Images/arrow_outward.png";
import infinite from "./Images/infinite.png";
import bubble from "./Images/bubble.png";
import filter from "./Images/filter.png";
import book from "./Images/book.png";
import diversity from "./Images/diversity1.png";
import toolbox from "./Images/toolbox.png";
import acute from "./Images/acute.png";
import manufacturing from "./Images/manufacturing.png";
import cycle from "./Images/cycle.png";
import share from "./Images/share.png";
import note from "./Images/export_notes.png";
import architecture from "./Images/architecture.png";
import Modal1 from "./Modal1";
const Node = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <div className="container node">
        <div className=" row noderow">
          <div className="col-md-4">
            <img src={node} width={300} />
          </div>
          <div className="col-md-8">
            <h2>Empower Your Business with Our Node.js Services</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid nodecolor">
        <div className="container node">
          <h2>
            Build Your Perfect Node.js <br />
            Application
          </h2>
          <p>
            Node.js is a server-side runtime environment built on Chrome's V8
            JavaScript engine. It allows developers to run JavaScript on the
            server-side, creating server-side applications with JavaScript.
            Node.js is primarily used to build server-side applications, but it
            can also be used to build command-line tools and desktop
            applications.One of the key benefits of Node.js is its ability to
            handle multiple requests concurrently. This is made possible by its
            non-blocking, event-driven architecture, which allows it to process
            multiple requests concurrently without waiting for each request to
            be completed before moving on to the next. This makes it well-suited
            for building real-time, high-concurrency applications such as
            chatrooms, online gaming, and real-time collaboration tools. Its basic modules
            {!isExpanded ? (
              <span className="dots">...</span>
            ) : (
              <span className="more">
                are written in JavaScript, thereby enabling
                the developers to effectively write new modules. JavaScript was
                once acknowledged as the best platform for frontend development,
                and Java, Python, PHP or Ruby were preferred for backend
                development. As a reliable NodeJS development company, we offer
                application development and maintenance services to ensure
                superior reliability and scalability for your business needs.
              </span>
            )}
          </p>
          <button className="custom-button" onClick={() => toggleExpand(0)}>
            {isExpanded ? "Read Less" : "Read More"}
            <span className="material-symbols-outlined">east</span>
          </button>
        </div>
      </div>
      
       <div className="container native">
        <h2>Why Node.js Development
        <br /> Services</h2>
        <div className="row angularframe">
          <div className="angularcontent">
            <span className="angularspan">
              <img src={acute} className="angularimg" alt="Angular 1" />
            </span>
            <h3>Node.js is fast</h3>
          </div>
          <div className="angularcontent">
            <span className="angularspan">
              <img src={manufacturing} className="angularimg" alt="Angular 2" />
            </span>
            <h3>Open source
            <br /> technology</h3>
          </div>
        </div>
        <div className="row angularframe1">
          <div className="angularcontent">
            <span className="angularspan">
              <img src={share} className="angularimg" alt="Angular 3" />
            </span>
            <h3>Node.js encourage sharing</h3>
          </div>
          <div className="angularcontent">
            <img src={node} width={200}/>
          </div>
          <div className="angularcontent">
            <span className="angularspan">
              <img src={cycle} className="angularimg" alt="Angular 4" />
            </span>
            <h3>Easily reusable modules</h3>
          </div>
        </div>
        <div className="row angularframe">
          <div className="angularcontent">
            <span className="angularspan">
              <img src={note} className="angularimg" alt="Angular 5" />
            </span>
            <h3>Provides a vide range
            <br /> of feature</h3>
          </div>
          <div className="angularcontent">
            <span className="angularspan">
              <img src={architecture} className="angularimg" alt="Angular 6" />
            </span>
            <h3>Even-driven
            <br /> architecture{" "}</h3>
          </div>
        </div>
        <button onClick={handleOpenModal}>
          Get In Touch <span className="material-symbols-outlined">east</span>
        </button>
        {isModalOpen && <Modal1 onClose={handleCloseModal} />}
      </div>
      <div className="container-fluid nodecolor1">
        <div className="container node">
          <h2>Benefits Of Node.js Development For Business</h2>
          <div className="row">
            <div className="col-md-6">
              <div className="nodelist">
                <div className="nodeinfo">
                  <div className="nodecontent">
                    <span className="nodespan1">
                      <img src={infinite} className="noded1" />
                    </span>
                    <div className="subinfo">
                      <h2>Performance</h2>
                      <p>
                        Node.js uses the V8 JavaScript engine, which was
                        developed by Google, to execute code. This engine
                        compiles and executes code at lightning speeds, making
                        Node.js applications fast and efficient.
                      </p>
                    </div>

                    <p></p>
                  </div>
                </div>
                <div className="nodeinfo">
                  <div className="nodecontent">
                    <span className="nodespan1">
                      <img src={bubble} className="noded1" />
                    </span>
                    <div className="subinfo">
                      <h2>Scalability</h2>
                      <p>
                      Node.js is highly scalable, making it ideal for handling substantial data and traffic. Adding new nodes to a Node.js system allows seamless scalability as your business expands.
                      </p>
                    </div>

                    <p></p>
                  </div>
                </div>
                <div className="nodeinfo">
                  <div className="nodecontent">
                    <span className="nodespan1">
                      <img src={filter} className="noded1" />
                    </span>
                    <div className="subinfo">
                      <h2>Asynchronous</h2>
                      <p>
                      Designed for asynchronous operations, Node.js handles multiple requests simultaneously without blocking the main thread, making it perfect for real-time applications like chat apps and online games.
                      </p>
                    </div>

                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="nodelist1">
                <div className="nodeinfo">
                  <div className="nodecontent">
                    <span className="nodespan1">
                      <img src={book} className="noded1" />
                    </span>
                    <div className="subinfo">
                      <h2>Extensive Library</h2>
                      <p>
                       Node.js boasts a large library of pre-built modules that can be effortlessly imported and used in applications, significantly reducing development time and effort.
                      </p>
                    </div>

                    <p></p>
                  </div>
                </div>
                <div className="nodeinfo">
                  <div className="nodecontent">
                    <span className="nodespan1">
                      <img src={diversity} className="noded1" />
                    </span>
                    <div className="subinfo">
                      <h2>Large Ecosystem</h2>
                      <p>
                      Supported by a vibrant community, Node.js offers a wide array of third-party modules and packages that can be easily integrated into applications.
                      </p>
                    </div>

                    <p></p>
                  </div>
                </div>
                <div className="nodeinfo">
                  <div className="nodecontent">
                    <span className="nodespan1">
                      <img src={toolbox} className="noded1" />
                    </span>
                    <div className="subinfo">
                      <h2>Great for Microservices</h2>
                      <p>
                      Node.js excels in building microservices, enabling the decomposition of large applications into smaller, independent services that can be developed and deployed independently.
                      </p>
                    </div>

                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Node;
