/* eslint-disable jsx-a11y/alt-text */


import React from "react";
import intern from "./Images/Intern.jpeg";
import Intern2 from "./Images/Intern2.jpeg";
import { Image } from "react-bootstrap";
import logo11 from "./Images/arrow_outward.png";
const Intern = () => {
  return (
    <>
      <div className="container intern">
        <div className="row nativerow">
          <div className="col-md-6">
            <img src={intern} height={300} />
          </div>
          <div className="col-md-6">
            <h2>Internship</h2>
          </div>
        </div>
      </div>
      
        <div className="container intern1">
          <div className="row internrow">
            <div className="col-md-4">
              <img src={Intern2} width="100%" height="500px"/>
            </div>
            <div className="col-md-8">
              <h2>About Bestowal</h2>
              <p>
                Bestowal Systems and Services Pvt. Ltd. offers advanced
                solutions for complex issues in SAP Implementation, Web Design,
                and Web Applications, leveraging extensive technical and
                managerial expertise. Our focus is on delivering superior
                outcomes through modern technologies, ensuring stakeholders
                benefit from competitive pricing advantages. As a leading SAP
                Solutions provider, Bestowal assists companies of all sizes and
                industries in achieving optimal performance. Our approach
                emphasizes efficiency throughout every stage of SAP module
                projects. Backed by seasoned industry professionals and a
                dedicated management team with extensive experience in SAP
                Implementation and IT Services, we tailor solutions to align
                with our clients' business objectives. We specialize in
                providing on-demand technological capBestowal's internship
                program offers a wide range of opportunities for eager students
                of high potential and recently graduated candidates to
                experience "hands-on" learning in the field of IT and SAP
                solutions related to Bestowal's mandate and activities Looking
                for. The objective of our internship program is to give interns
                exposure to bestowal daily work over a period of six months. As
                an IT trainee, you will be working on innovations for today and
                every day for tomorrow. We have opportunities for students
                majoring in SAP Services, Software Development, Web Designing
                and Digital Marketing. You will participate in virtual or
                in-person networking events and community outreach events,
                helping to design and build our next generation of projects. As
                a Bestowal intern, you will develop your skill set, build
                lasting networks, and contribute to projects that shape
                aerospace innovation. Don't miss out on this opportunity to make
                a difference. abilities to meet diverse digital requirements,
                driven by a commitment to our clients' success. Our dedication
                to research and development keeps us at the forefront of
                innovation, enabling us to deliver bespoke software solutions
                effectively and affordably, with a focus on meeting deadlines
                and ensuring high client satisfaction. Through strategic
                collaborations with B2B and B2C businesses, we transform demands
                and objectives into tangible software products. Our ability to
                deliver software solutions promptly and reliably is supported by
                cutting-edge test automation capabilities and infrastructure.
              </p>
              <button>
            Read More <Image src={logo11} className="img6" />
          </button>
            </div>
          </div>
        </div>
    </>
  );
};

export default Intern;
