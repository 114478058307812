import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";

const Breadcrumb = ({ blogTitle }) => {
  const location = useLocation();
  const { id } = useParams();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <nav aria-label="breadcrumb" className="mb-4">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;

          return isLast && blogTitle ? (
            <li key={index} className="text-dark" style={{fontSize:"12px"}} aria-current="page">
              {blogTitle}
            </li>
          ) : (
            <li key={index} className="breadcrumb-item">
              <Link to={routeTo}>
                {name === "blog"
                  ? "Blogs"
                  : name.charAt(0).toUpperCase() + name.slice(1)}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
