
import React from "react";
import sapibpimg from "./Images/Why_SAP_IBP.png";
import howsapimg from "./Images/howsap.png";
import Collaborative_Solutions from "./Images/Collaborative_Solutions.jpg";
import Expertise from "./Images/Expertise.jpg";
import Experienced_Leadership from "./Images/Experienced_Leadership.jpg";
import Focus from "./Images/Focus.jpg";
import SAP_Transform from "./Images/SAP_Transform.jpg";
import Technology_Expert from "./Images/Technology_Expert.jpg";
import Principal from "./Images/Principal.png";
import Response from "./Images/Response.png";
import Sales from "./Images/Sales.png";
import Supply from "./Images/Supply.png";
import Operation from "./Images/Operation.png";
import Demand from "./Images/Demand.png";
import Production from "./Images/Production.png";
import SAP_IBP from "./Images/SAP_IBP.png";
import Started from "./Images/Started.png";
import SAP_Cloud from "./Images/SAP_Cloud.png";
import roadmap from "./Images/roadmap.png";
import SAP from "./Images/SAP.jpg";
// import bgsapibp from "./Images/bg-why-SAP-IBP.png";
const SAPIBP = () => {
  return (
    <>
      <div className="container-fluid sap">
        <img src={SAP} alt="SAP_IBP Image" title="SAP Image" />
      </div>
    
      <div className="container-fluid bgsapibp">
        <div className="container sapibp">
          <div className="roadmap-center">
            <img
              src={sapibpimg}
              className="roadmap_image"
              title="Why Choose SAP IBP? Comprehensive Consultation and Integration"
              alt="Why SAP IBP?"
            />
            <h2>
              Why Bestowal
              <br /> For SAP IBP
            </h2>
          </div>

          <div className="roadmap-item item1">
            <h3>Robust Business Solutions</h3>
            <p>
              Digital Maturity assessment to build North star for organizations.
              Deep dive on potential platforms and integrations for digital
              transformation.{" "}
            </p>
          </div>

          <div className="roadmap-item item2">
            <h3>Levers for Customer Centricity</h3>
            <p>
              Can deliver Customer Centricity that has a direct impact on P&L by
              using Levers like Optimise Distribution cost, Improve customer
              experience via Supply Chain KPIs.
            </p>
          </div>

          <div className="roadmap-item item3">
            <h3>Value Case & Strategic Roadmap</h3>
            <p>
              E2E involvement from solution selection to post Go-Live Support,
              360 deg value definition, requirement definition.{" "}
            </p>
          </div>

          <div className="roadmap-item item4">
            <h3>Change Management & Governance</h3>
            <p>
              Change Strategy for key stakeholders & Leadership suite.
              Governance for Design review & Change Control.{" "}
            </p>
          </div>

          <div className="roadmap-item item5">
            <h3>
              Global Network of <br />
              Supply Chain Experts
            </h3>
            <p>
              a. Highly proficient Technology experts, aligned together in our
              broader network to bring Innovation to our clients every day.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid howsap">
        <div className="container howsap_section1">
          <div className="row">
            <div className="col-md-4">
              <img
                src={howsapimg}
                alt="How SAP IBP Can Transform Your Business Planning"
                title="SAP IBP Transforming Business Planning"
              />
            </div>
            <div className="col-md-8" style={{ marginTop: "0px" }}>
              <h2>Business Solutions Delivered</h2>
              <p>
                <b>Context:</b> CONSUMER GOODS COMPANY – USA was facing
                challenges in Planning Processes. <br />
                <br />
                <b>Key Challenges:</b>
                <br />
                <ul>
                  <li>To implement IBP Supply in Constrained & Unconstrained mode for
                Business Unit in order to digitalize, standardize and create a
                more agile RCCP process for the mid to long-term horizon.</li>
                  <li>High volume and mix business with a product portfolio
                of around 20000+ SKU’s, predominantly dealing with a large
                variety of Suppliers.</li>
                <li>Highly competitive and volatile business environment
                make the synchronization of demand and supply very critical</li>
                </ul>
               
                <br />
                <br />
                <b>Value delivered</b>:<br />
                Successful migration of the custom processes to Standard ones
                <br /><br/>
                <ul>
                  <li>Implementation of Process Improvements & Gaps</li>
                  <li>Better balance demand and supply so as to meet the
                  business/customer expectations.</li>
                  <li>IBP integrated Demand and Supply into one, easy to use
                tool that allows for agility and flexibility in RCCP. By
                transforming its planning processes through a combination of
                advanced tools, enhanced visibility, automation, and improved
                collaboration, the consumer goods company was able to overcome
                its challenges and achieve a more efficient, agile, and
                customer-focused operation. This holistic approach not only
                optimized internal processes but also positioned the company for
                sustained growth and success in a competitive market.</li>
                </ul>
                 
                <br />
                <br /> 
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid SAP_Alternatives">
        <div className="SAPIBP_Alternatives">
          <div className="container Alternatives">
            <h1>SAP IBP Alternatives</h1>
            <p>
              Comparing SAP IBP to Other Supply Chain Planning Tools on the
              Market
            </p>
            <div className="row">
              <div className="col-md-4">
                <div className="sapcards">
                  <div className="card-body">
                    <div className="sap-container">
                      <img
                        src={Collaborative_Solutions}
                        className="img-fluid"
                        alt="Collaborative_Solutions"
                        title="Collaborative Solutions for Tailored Business Configurations"
                      />
                    </div>
                    <h5>Diagnostic</h5>
                    <p>
                      Maturity Assessment - Proof of concept & Establishing
                      bottom up and top down kpi baseline.It provides a powerful
                      framework for assessing an organization’s maturity and
                      readiness for change. This approach ensures that strategic
                      goals are aligned with operational capabilities, minimizes
                      risks, enhances stakeholder buy-in, and drives continuous
                      improvement, ultimately leading to sustained success and
                      growth.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sapcards">
                  <div className="card-body">
                    <div className="sap-container">
                      <img
                        src={Expertise}
                        className="img-fluid"
                        alt="SAP IBP Development Expertise"
                        title="Expertise in SAP IBP Development with Proven Project Success"
                      />
                    </div>
                    <h5>Analytics driven insights</h5>
                    <p>
                      Custom analytics across value chain for optimisation of
                      various KPIs By leveraging advanced data analytics,
                      businesses can gain deep insights into every aspect of
                      their operations — from sourcing and production to
                      distribution and customer engagement. This enables them to
                      identify inefficiencies, predict trends, and make
                      data-driven decisions to improve performance and achieve
                      strategic objectives.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sapcards">
                  <div className="card-body">
                    <div className="sap-container">
                      <img
                        src={Experienced_Leadership}
                        alt="Experienced_Leadership"
                        title="Experienced Leadership Member"
                        className="img-fluid"
                      />
                    </div>
                    <h5>Art of Possible</h5>
                    <p>
                      Delivering workshops to showcase IBP Capabilities &
                      industry leading practices These sessions provide a
                      hands-on, interactive experience that allows participants
                      to understand the value of IBP, how it aligns with their
                      business objectives, and the best practices to optimize
                      their planning processes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sapcards">
                  <div className="card-body">
                    <div className="sap-container">
                      <img
                        src={Focus}
                        className="img-fluid"
                        alt="Focus on reliability and results in IT solutions"
                        title="Focus on reliability and results in IT solutions"
                      />
                    </div>
                    <h5> Transformation Blueprint</h5>
                    <p>
                      Generating business case for transformation journey It
                      involves presenting a clear and persuasive argument for
                      why the transformation is necessary, how it will be
                      achieved, and what benefits it will deliver. A
                      well-structured business case helps stakeholders
                      understand the value of the transformation, align on
                      objectives, and secure buy-in and resources for successful
                      implementation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sapcards">
                  <div className="card-body">
                    <div className="sap-container">
                      <img
                        src={SAP_Transform}
                        className="img-fluid"
                        alt="SAP transformation services"
                        title="SAP transformation services"
                      />
                    </div>
                    <h5>Transformation enablement</h5>
                    <p>
                      Define new operating model for ways of working in a
                      platform enabled planning function It leverages technology
                      platforms to streamline, integrate, and enhance planning
                      processes across an organization. By assessing the current
                      state, selecting the right platform, redesigning
                      processes, and implementing robust governance and change
                      management strategies, organizations can successfully
                      transform their planning functions to be more efficient.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sapcards">
                  <div className="card-body">
                    <div className="sap-container">
                      <img
                        src={Technology_Expert}
                        className="img-fluid"
                        alt="Expertise in SAP implementation and technological advancements"
                        title="Expertise in SAP implementation and technological advancements"
                      />
                    </div>
                    <h5>Model Sustenance</h5>
                    <p>
                      Playbook for users and ready reckoner for long term usage
                      of solution within multiple groups A ready reckoner is
                      crucial for ensuring that users across various groups can
                      effectively adopt and utilize a new solution over the long
                      term. This playbook should serve as a comprehensive guide
                      that covers all aspects of using the solution, from
                      initial onboarding to advanced usage. The ready reckoner
                      will provide quick reference points for common tasks and
                      troubleshooting.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid SAP_Principle">
        <div className="container SAPIBP_principle">
          <h1>Major Business functionalities supported by SAP IBP</h1>
          <div className="row">
            <div className="col-md-4">
              <div className="principalcards">
                <div className="card-body">
                  <div className="sap-container">
                    <img
                      src={Response}
                      className="img-fluid"
                      alt="Response and Supply Planning for Strategic Decision-Making"
                      title="Response and Supply Planning for Strategic Decision-Making"
                    />
                  </div>
                  <h5>Supply Planning</h5>
                  <p>
                    Unconstrained Supply Plan: Ideal scenario without
                    constraints, for meeting all demand. Constrained Supply
                    Plan: Realistic plan considering limitations in capacity and
                    resources. Material Requirements Planning (MRP): Manages
                    inventory and production scheduling based on material needs.
                    Short-Term Operational Supply Plan: Focuses on immediate
                    supply chain needs and operational adjustments.
                    Priority-Based Supply Plan: Allocates resources based on
                    order priorities and strategic importance. Allocation
                    Planning: Distributes inventory and capacity based on demand
                    and priorities. Rough-Cut Capacity Planning (RCCP):
                    Evaluates high-level capacity versus forecasted demand.
                    Deployment Planning: Manages the distribution of finished
                    goods to various locations. Transportation Load Building
                    (TLB): Optimizes the loading of goods onto transportation
                    vehicles to reduce costs and maximize space.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="principalcards">
                <div className="card-body">
                  <div className="sap-container">
                    <img
                      src={Sales}
                      className="img-fluid"
                      alt="Sales and Operation Planning for Supply Chain Optimization"
                      title="Sales and Operation Planning for Supply Chain Optimization"
                    />
                  </div>
                  <h5>
                    Sales & Operation
                    <br /> Planning
                  </h5>
                  <p>
                    Product Review: Assesses product performance, quality, and
                    market fit to ensure alignment with customer needs and
                    expectations.  Demand &amp; Supply Review: Aligns demand
                    forecasts with supply capabilities to balance inventory and
                    optimize supply chain operations.  Reconciliation Review:
                    Compares and reconciles data to ensure accuracy and
                    consistency, addressing discrepancies as needed.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="principalcards">
                <div className="card-body">
                  <div className="sap-container">
                    <img
                      src={Supply}
                      className="img-fluid"
                      alt="Supply Planning and Procurement Strategy"
                      title="Supply Planning and Procurement Strategy"
                    />
                  </div>
                  <h5>Demand Planning</h5>
                  <br />
                  <br />
                  <p>
                    Demand Sensing focuses on real-time data to improve
                    short-term forecast accuracy and responsiveness.  Long-Term
                    Demand Planning aligns forecasts with strategic business
                    goals and long-term market trends.  Product Life Cycle
                    Planning manages demand across different product stages to
                    optimize performance and profitability.  Forecast
                    Automation leverages algorithms and machine learning to
                    automate and refine forecasting processes.  Statistical
                    Forecasting uses historical data and mathematical models to
                    generate accurate demand forecasts.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="principalcards">
                <div className="card-body">
                  <div className="sap-container">
                    <img
                      src={Operation}
                      className="img-fluid"
                      alt="Inventory Optimization"
                      title="Inventory Optimization"
                    />
                  </div>
                  <h5>Inventory Optimization</h5>
                  <p>
                    Safety Stock Planning: Maintains additional inventory to
                    buffer against demand and supply uncertainties, ensuring
                    continuity of supply.  Single-Stage Inventory Planning:
                    Manages inventory at a single location or stage, focusing on
                    local optimization.  Multi-Stage Inventory Planning:
                    Manages inventory across multiple stages or locations,
                    optimizing the entire supply chain.  Calculation of Safety
                    Stock: Determines the buffer inventory needed based on
                    demand variability and lead time.  Calculation of Cycle
                    Stock: Represents inventory used to meet regular demand,
                    calculated as half of the order quantity.  Calculation of
                    Pipeline Stock: Accounts for inventory in transit,
                    calculated based on demand rate and lead time.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="principalcards">
                <div className="card-body">
                  <div className="sap-container">
                    <img
                      src={Demand}
                      className="img-fluid"
                      alt="Demand Forecasting"
                      title="Demand Forecasting"
                    />
                  </div>
                  <h5>Advanced availability to Promise: </h5>
                  <p>
                    Sales Order Confirmation: Verifies and confirms order
                    details and inventory availability before processing.  Back
                    Order Processing: Manages orders for out-of-stock items,
                    which are fulfilled when inventory is replenished (not
                    supported in the current system).  Rule-Based ATP:
                    Determines product availability and promise dates based on
                    predefined rules and constraints (not supported in the
                    current system).
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="principalcards">
                <div className="card-body">
                  <div className="sap-container">
                    <img
                      src={Production}
                      className="img-fluid"
                      alt="Production Scheduling"
                      title="Production Scheduling"
                    />
                  </div>
                  <h5>Demand Driven MRP</h5>
                  <p>
                    Buffer Positioning: Strategically places inventory buffers
                    within the supply chain to manage variability and ensure
                    smooth operations.  Buffer Profiling: Analyzes and
                    categorizes inventory buffers based on demand patterns and
                    product characteristics for tailored strategies.  Dynamic
                    Adjustments: Continuously modifies buffer levels based on
                    real-time data and changing conditions to optimize inventory
                    management.  Exclude-Order Release: Selectively excludes
                    certain orders from regular release processes based on
                    predefined criteria for prioritization and special handling.
                     Execution Monitoring: Tracks and oversees the execution of
                    supply chain activities to ensure they are performed as
                    planned and identifies any deviations or issues.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="container-fluid Started_SAPIBP">
        <div className="container Started">
          <h1>Key Value Drivers of SAP IBP </h1>
          <div className="text-container">
            <div className="step1">
              <h1>Value Led Transformation</h1>
              <p class="description">
                chain. It involves identifying key value drivers, designing
                solutions, and creating a roadmap to achieve desired outcomes.
              </p>
            </div>
            <div className="step2">
              <h1>Process consulting</h1>
              <p class="description">
                To deliver a solution iteratively and incrementally. The
                approach focuses on developing and releasing MVPs to validate
                concepts and gather feedback,
              </p>
            </div>
            <div className="step3">
              <h1>Program Management: </h1>
              <p class="description">
                It ensures that the proposed solution meets business needs and
                fits within existing capabilities while identifying any gaps
                that need to be addressed.
              </p>
            </div>
            <div className="step4">
              <h1>Value Realization</h1>
              <p class="description">
                These processes ensure that the IBP system is well-designed,
                configured, tested, and adopted, leading to successful
                implementation and alignment with business objectives.
              </p>
            </div>
          </div>
          <img
            src={Started}
            alt="How to Started With SAP IBP"
            title="How to Started With SAP IBP"
          />
        </div>
      </div>

      <div className="container-fluid roadmap">
        <div className="container saproadmap">
          <h1>Our Delivery Methodology for E2E Projects</h1>
          <p>
            We strategize ahead to support, we help our customers in every
            dimension
            <br /> of the supply chain...
          </p>
          {/* <img src={SAP_Cloud} className="SAP_Cloud" alt="SAP Cloud" title="SAP CLoud"/> */}
          <div className="clouds">
            <h2>Process assessment workshops</h2>
            <h2>Platform selection</h2>
            <h2>Solution Approach</h2>
          </div>
          <img
            src={roadmap}
            className="roadmapimg"
            alt="Roadmap For SAP IBP Implementation"
            title="Roadmap For SAP IBP Implementation"
          />
          <div className="Clouds">
            <h2>Configuration and Development</h2>
            <h2>Change Management</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default SAPIBP;
