
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Career = () => {
  const initialJobs = [
    { title: "SAP ISU Module", location: "Pune, MH, IN", date: "Sep 3, 2024" },
    { title: "React Developer", location: "Mumbai, MH, IN", date: "Sep 4, 2024" },
    { title: "Fullstack Developer", location: "Bangalore, KA, IN", date: "Sep 5, 2024" },
    { title: "SAP ABAP Consultant", location: "Pune, MH, IN", date: "Sep 6, 2024" },
    { title: "UI/UX Designer", location: "Chennai, TN, IN", date: "Sep 7, 2024" },
    { title: "Project Manager", location: "Hyderabad, TS, IN", date: "Sep 8, 2024" },
    { title: "Software Engineer", location: "Delhi, DL, IN", date: "Sep 9, 2024" },
    { title: "QA Tester", location: "Noida, UP, IN", date: "Sep 10, 2024" },
    { title: "SAP IBP", location: "Mahashtra, Pune, IN", date: "Sep 10, 2024" },
    { title: "SAP ABAP", location: "Mahashtra, Mumbai, IN", date: "Sep 10, 2024" },
  ];

  const [jobs, setJobs] = useState(initialJobs);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 3;

  const navigate = useNavigate(); // Using useNavigate to programmatically navigate

  const handleSearch = () => {
    const filteredJobs = initialJobs.filter((job) => {
      const isTitleMatch = job.title.toLowerCase().includes(searchTitle.toLowerCase());
      const isLocationMatch = job.location.toLowerCase().includes(searchLocation.toLowerCase());
      return isTitleMatch && isLocationMatch;
    });
    setJobs(filteredJobs);
    setCurrentPage(1); // Reset to the first page when search is performed
  };

  const handleReset = () => {
    setSearchTitle("");
    setSearchLocation("");
    setJobs(initialJobs);
    setCurrentPage(1);
  };

  const handleJobClick = (jobTitle) => {
    switch (jobTitle.toLowerCase()) {
      case "sap isu module":
        navigate("/sapisu"); // Navigate to the SAP ISU route
        break;
      case "react developer":
        navigate("/react-developer"); // Navigate to the React Developer route
        break;
      case "fullstack developer":
        navigate("/fullstack-developer"); // Navigate to the Fullstack Developer route
        break;
      // Add more cases for other job titles as needed
      default:
        const formattedTitle = jobTitle.replace(/\s+/g, "-").toLowerCase();
        navigate(`/job/${formattedTitle}`); // Fallback for other job titles
    }
  };
  

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);

  return (
    <>
      <div className="container-fluids careers">
        <h1>Career at Bestowal</h1>
      </div>

      <div className="container career">
        <div className="search-section text-white">
          <div className="row careerrow">
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Job Title"
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
            </div>

            <div className="col-md-4">
              <select
                className="form-control"
                value={searchLocation}
                onChange={(e) => setSearchLocation(e.target.value)}
              >
                <option value="">All Locations</option>
                <option value="Pune, MH, IN">Pune, MH, IN</option>
                <option value="Mumbai, MH, IN">Mumbai, MH, IN</option>
                <option value="Bangalore, KA, IN">Bangalore, KA, IN</option>
                <option value="Chennai, TN, IN">Chennai, TN, IN</option>
                <option value="Hyderabad, TS, IN">Hyderabad, TS, IN</option>
                <option value="Delhi, DL, IN">Delhi, DL, IN</option>
                <option value="Noida, UP, IN">Noida, UP, IN</option>
              </select>
            </div>

            <div className="col-md-2">
              <button className="careerbutton" onClick={handleSearch}>
                Search Jobs
              </button>
            </div>
            <div className="col-md-2">
            <button className="resetbtn" onClick={handleReset}>
              Reset
            </button>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center mb-3" style={{ padding: "50px 0px" }}>
          <div className="ms-auto">
          <nav aria-label="Job pagination">
                <ul className="pagination mb-0">
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      «
                    </button>
                  </li>
                  {[...Array(Math.ceil(jobs.length / jobsPerPage)).keys()].map((pageNumber) => (
                    <li
                      key={pageNumber + 1}
                      className={`page-item ${currentPage === pageNumber + 1 ? "active" : ""}`}
                    >
                      <button className="page-link" onClick={() => setCurrentPage(pageNumber + 1)}>
                        {pageNumber + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={currentPage === Math.ceil(jobs.length / jobsPerPage)}
                    >
                      »
                    </button>
                  </li>
                </ul>
              </nav>
          </div>
        </div>

        <div className="container jobtable">
          <div className="row jobtablerow">
            <div className="col-md-12">
              <table className="table job-header">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Location</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {currentJobs.map((job, index) => (
                    <tr key={index} onClick={() => handleJobClick(job.title)} style={{ cursor: "pointer" }}>
                      <td>{job.title}</td>
                      <td>{job.location}</td>
                      <td>{job.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
