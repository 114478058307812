/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
// import React from 'react'
import AdvanceDotnetApplication from "./Images/AdvanceDotnetApplication.png";
import React, { useState } from "react";
import dotnet from "./Images/dotnet.jpeg";
import { Image } from "react-bootstrap";
import logo11 from "./Images/arrow_outward.png";
import Card from "react-bootstrap/Card";
import dsad from "./Images/DSAD.png";
import mobile from "./Images/Mobile.png";
import web from "./Images/web.png";
import cloud from "./Images/cloudservice.jpeg";
import sm from "./Images/SM.png";
import cms from "./Images/cms.png";
import backend from "./Images/backend.png";
import frontend from "./Images/frontend.png";
import database from "./Images/database.png";
import deployment from "./Images/deployment.png";
import testing from "./Images/testing.png";
import architecture from "./Images/architecture.png";
const Dotnet = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={dotnet} width={300} />
          </div>
          <div className="col-md-8">
            <h2>DotNet Development</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid nativecolor">
        <div className="container native">
          <h2>What is “.Net”?</h2>
          <p>
            .NET development involves creating applications using the Microsoft
            .NET platform and programming languages such as C#, F#, or Visual
            Basic. ASP.NET Core, a server-side framework within .NET, is used to
            build dynamic websites, applications, and services. It is suitable
            for both large enterprise applications and small to mid-sized
            projects. Companies looking to enhance their IT capabilities quickly
            and reduce delivery times often choose custom .NET software
            development services Bestowal Systems and Services Private Limited
            should be your go-to company for .NET development! One of our top
            priorities is the creation of specialized software using .NET
            technology. Our .NET experts have been providing .NET programming
            services to clients around the globe for many years. At Bestowal, we
            specialize in creating robust and multi-functional web applications,
            .NET-based applications, internet and intranet websites, and
            web-based business processes.
            {!isExpanded ? (
              <span className="dots">...</span>
            ) : (
              <span className="more">
                As one of the leading software development firms, we have
                successfully completed projects of varying complexity across a
                wide range of industries. Our .NET engineers are committed to
                delivering practical business solutions and stay updated with
                the latest trends and advancements in Microsoft technologies to
                ensure we provide our clients with the most current and
                effective solutions.
              </span>
            )}
          </p>
          <button className="sapbtn" onClick={() => toggleExpand(0)}>
            {isExpanded ? "Read Less" : "Read More"}
            <Image src={logo11} className="img0" />
          </button>
        </div>
      </div>
      <div className="container-fluid dotnetcolor">
        <div className="container dotnet">
          <h2>.Net Web Development</h2>
          <p>
          With the help of tools and frameworks for developing web apps and services for Windows, Linux, macOS, and Docker, we build web pages, REST APIs/microservices, and real-time apps using the ASP.NET Core extension of the .NET platform.
          </p>
          <div className="row dotnetrow">
            <div className="col-md-4">
              <Card>
                <Card.Body>
                  <Card.Title>Websites</Card.Title>
                  <Card.Text>
                  We create secure, fast, and scalable websites with interactive UIs and dynamic web content based on HTML5, CSS, and JavaScript using .NET and C#. Our experts have experience with popular databases like SQLite, SQL Server, MySQL, PostgreSQL, and DB2, as well as non-relational stores like MongoDB, Redis, and Azure Cosmos DB.
Our websites are designed to handle high traffic volumes and provide an exceptional user experience. 
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card>
                <Card.Body>
                  <Card.Title>Real-Time Apps</Card.Title>
                  <Card.Text>
                  We leverage SignalR to bring real-time capabilities to dashboards, maps, games, chats, and other applications that require frequent updates from the server. Our SignalR-based server apps ensure your customer-facing apps can receive real-time communications on web, mobile, or desktop platforms without needing a refresh.
Our real-time applications enable instant data synchronization and live updates, enhancing user engagement and interactivity.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card>
                <Card.Body>
                  <Card.Title>Rest APIs and Microservices </Card.Title>
                  <Card.Text>
                  We break down complex software solutions (for mobile, desktop, gaming, web, and more) into REST microservices using ASP.NET Core, the .NET web framework. These microservices can operate within Docker containers on all major cloud platforms, including Azure. We assist in implementing microservices without the need to rewrite your entire application and can integrate microservices developed in Java, Go, Node.js, .NET, or any other language.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid nodecolor1">
        <div className="container node">
          <h2>Benefits Of Dot Net Development For Business</h2>
          <div className="row">
            <div className="col-md-6">
              <div className="nodelist">
                <div className="nodeinfo">
                  <div className="nodecontent">
                    <span className="nodespan1">
                      <img src={dsad} className="dotnetimg" />
                    </span>
                    <div className="subinfo">
                      <h2>.NET Web Development </h2>
                      <p>
                      Transform your business needs into adaptable and scalable .NET web-based solutions with our expertise. Our years of experience can help you succeed in the dynamic business world.
                      </p>
                    </div>

                    <p></p>
                  </div>
                </div>
                <div className="nodeinfo">
                  <div className="nodecontent">
                    <span className="dotnetspan1">
                      <img src={mobile} className="dotnetimg" />
                    </span>
                    <div className="subinfo">
                      <h2>Desktop Software App Development </h2>
                      <p>
                      Get robust and user-friendly desktop applications created by experts using WPF and WinForms. We optimize your business apps to ensure they operate quickly and efficiently.
                      </p>
                    </div>

                    <p></p>
                  </div>
                </div>
                <div className="nodeinfo">
                  <div className="nodecontent">
                    <span className="dotnetspan1">
                      <img src={web} className="dotnetimg" />
                    </span>
                    <div className="subinfo">
                      <h2>Mobile App Development</h2>
                      <p>
                      Enhance your business operations and mobile app development with Xamarin. Our experts use Xamarin to create high-quality, cross-platform apps that bridge communication gaps.
                      </p>
                    </div>

                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="nodelist1">
                <div className="nodeinfo">
                  <div className="nodecontent">
                    <span className="dotnetspan1">
                      <img src={cloud} className="dotnetimg" />
                    </span>
                    <div className="subinfo">
                      <h2>.NET Cloud Integration Services</h2>
                      <p>
                      Run your business seamlessly on the Azure cloud platform with our .NET cloud integration services. We are dedicated to providing a considerate and exceptional service for a positive overall experience.
                      </p>
                    </div>

                    <p></p>
                  </div>
                </div>
                <div className="nodeinfo">
                  <div className="nodecontent">
                    <span className="dotnetspan1">
                      <img src={sm} className="dotnetimg" />
                    </span>
                    <div className="subinfo">
                      <h2>Support And Maintenance </h2>
                      <p>
                      Our knowledgeable support specialists are happy to assist with everything from simple concerns to complex problems. Our highly qualified support engineers provide unique solutions.
                      </p>
                    </div>

                    <p></p>
                  </div>
                </div>
                <div className="nodeinfo">
                  <div className="nodecontent">
                    <span className="dotnetspan1">
                      <img src={cms} className="dotnetimg" />
                    </span>
                    <div className="subinfo">
                      <h2>.NET CMS Application Development</h2>
                      <p>
                      We take pride in offering our clients the tools and support needed to streamline and simplify the management of their website content. Our ultimate goal is to help our clients achieve top search engine rankings.
                      </p>
                    </div>

                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container native">
        <h2>Advanced .NET Development Experience</h2>
        <img src={AdvanceDotnetApplication} alt="" />
      </div>
      <div className="container-fluid seo">
        <div className="container android">
          <h2>
            Modernization Of Your.NET-Based Software & Associated Infrastructure
            To Boost Performance
          </h2>
          <p>
          If your employees or clients encounter issues with the performance of your .NET-based applications on web and mobile devices, and you require rapid scalability of your .NET development team, we can provide assistance.
          </p>
          <div class="container accordion">
            <div class="accordion-container">
              <div class="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-1"
                  class="accordion-checkbox"
                />
                <label for="accordion-item-1" class="accordion-header">
                  <span>01</span>
                  <p>Enhancing Current Capabilities</p>
                  <div class="arrow"></div>
                </label>
                <div class="accordion-body">
                  <ul class="custom-list">
                    <li>
                    Specific modification of legacy code using more contemporary and effective techniques to expedite tasks.
                    </li>
                    <li>
                    Optimizing query processing time for customer queries across databases (MSSQL, MySQL, PostgreSQL, SQLite, LiteDB, etc.).
                    </li>
                    <li>
                    Redesigning software architecture, if necessary, to decouple frontend and backend through developing SPAs for each application, using REST Web APIs to enhance server performance by 30%.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-2"
                  class="accordion-checkbox"
                />
                <label for="accordion-item-2" class="accordion-header">
                  <span>02</span>
                  <p>Quality assurance and.NET software testing</p>
                  <div class="arrow"></div>
                </label>
                <div class="accordion-body">
                  <ul class="custom-list">
                    <li>
                    Ensuring outstanding software performance through functional testing, performance testing, migration testing, and other necessary testing methods.
                    </li>
                    <li>
                    Enhancing user experience through usability testing, GUI testing, and other relevant testing types.
                    </li>
                    <li>
                    Using integration testing and regression testing to seamlessly add new features to your .NET products.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-3"
                  class="accordion-checkbox"
                />
                <label for="accordion-item-3" class="accordion-header">
                  <span>03</span>
                  <p>Migration to the cloud</p>
                  <div class="arrow"></div>
                </label>
                <div class="accordion-body">
                  <ul class="custom-list">
                    <li>
                    Creating a proof of concept (POC) for migrating your .NET software to the cloud, then selecting the most suitable cloud provider based on expected business benefits.
                    </li>
                    <li>
                    Evaluating cost and scope to deliver a convenient and appropriate migration plan.
                    </li>
                    <li>
                    Updating your .NET application to be cloud-ready, reducing migration expenses and meeting the requirements of the chosen cloud provider.
                    </li>
                    <li>
                    Breaking down the migration process into stages, such as database migration and security enhancements, to facilitate nearly zero downtime deployment of your .NET product to the cloud.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-4"
                  class="accordion-checkbox"
                />
                <label for="accordion-item-4" class="accordion-header">
                  <span>04</span>
                  <p>Migration Services for.NET (Migrating to .NETCore)</p>
                  <div class="arrow"></div>
                </label>
                <div class="accordion-body">
                  <ul class="custom-list">
                    <li>
                    Ensuring .NET Core compliance to prevent compatibility or accessibility issues post-migration.
                    </li>
                    <li>
                    Upgrading incompatible .NET Core technologies and ensuring all critical dependencies, including APIs, remain functional.
                    </li>
                    <li>
                      NMigrating native and third-party platform-specific libraries to .NET Core.
                    </li>
                    <li>
                    Performing additional optimization of .NET software if needed, such as query profiling, database optimization, reducing reliance on stored procedures, and leveraging more efficient .NET Core APIs after migration.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" container sapibp">
        <div className="sapheading">
          <h2>Why Choose Bestowal For “.Net” Development?</h2>
        </div>
        <div className=" row listgrp">
          <div className="col-md-6">
            <div className="dotnetinfo1">
              <div className="caption1">
                <p>
                  Our Microsoft Net development team (talented project managers,
                  business analysts, dotNet developers, QA and support
                  engineers, and system administrators) is prepared to
                  contribute their knowledge. Our.NET software developers will
                  create a workable implementation plan for your ideas and
                  provide a product that supports your company's objectives.
                  {" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="dotnetinfo1">
              <div className="caption1">
                <p>
                  We have demonstrated proficiency in the domain of dotNet
                  programming and have earned a Microsoft certification. Our
                  programmers passed all three of the required examinations to
                  earn the official MCSD Web Applications status certificate
                  Developing ASP.NET MVC Web Applications, Programming in HTML5
                  with JavaScript and CSS3, and Developing Microsoft Azure and
                  Web Services.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="dotnetinfo1">
              <div className="caption1">
                <p>
                  Whether you have a minor or large project, hire .Net
                  programmers at Bestowal. Long employees of our Microsoft Net
                  application software have placed custom .Net web development
                  orders globally and are currently receiving technical
                  maintenance and customer support services for the generated
                  goods.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="dotnetinfo1">
              <div className="caption1">
                <p>
                  We have extensive.NET development experience, including ASP
                  development. Examples of our work include web apps, web
                  portals, unique SharePoint-based EDMS and eLearning solutions,
                  applications for Office 365, etc. View a few of our.NET case
                  studies.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="dotnetinfo1">
              <div className="caption1">
                <p>
                  The time to market is shortened when.NET application
                  development is outsourced, and deliveries are made on schedule
                  and within budget thanks to committed teams and adaptable
                  working circumstances. Our teams leverage their extensive
                  expertise to ensure high-quality and scalable solutions that
                  meet your business needs. By outsourcing to us, you gain
                  access to a pool of talent that can quickly adapt to your
                  project requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="dotnetinfo1">
              <div className="caption1">
                <p>
                  All of the services we offer, from advising to product
                  creation and maintenance, are valued and trusted by our
                  clients. We pride ourselves on building strong, long-lasting
                  relationships with our clients by delivering exceptional
                  service and support. Our commitment to excellence ensures that
                  every project we undertake is completed to the highest
                  standards. Our holistic approach to development includes
                  thorough planning, continuous feedback loops, and post-launch
                  support, ensuring your product evolves with your business
                  needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="fluid-container">
        
        <div className="container">
  <div className="row">
    <div className="col-12 col-md-6 digitalframe2">
     
      <div className="digital2">
        <div className="warantycards">
          <div className="dotnetprocess1">
            <span className="processspan1">
              <img src={backend} className="d11" />
            </span>
            <h3>Backend</h3>
            <p>.NET | .NET Core | ASP.NET | C# | VB.NET</p>
          </div>
        </div>
        <div className="warantycards">
          <div className="dotnetprocess1">
            <span className="processspan1">
              <img src={frontend} className="d11" />
            </span>
            <h3>Frontend</h3>
            <p>React | Vue | Bootstrap | Angular | JQuery | WPF</p>
          </div>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-6 digitalframe1">
     
      <div className="warantycards">
        <div className="dotnetprocess1">
          <span className="processspan1">
            <img src={database} className="d11" />
          </span>
          <h3>Database</h3>
          <p>MS-SQL | MariaDB | MongoDB | MySQL | PostgreSQL | Oracle</p>
        </div>
      </div>
      <div className="warantycards">
        <h2>Our Tech Stack</h2>
      </div>
      <div className="warantycards">
        <div className="dotnetprocess1">
          <span className="processspan1">
            <img src={deployment} className="d11" />
          </span>
          <h3>Deployment</h3>
          <p>AWS | Azure | Kubernetes | Google Cloud | Docker</p>
        </div>
      </div>
    </div>
    <div className="col-12 digitalframe2">
   
      <div className="digital2">
        <div className="warantycards">
          <div className="dotnetprocess1">
            <span className="processspan1">
              <img src={testing} className="d11" />
            </span>
            <h3>Testing</h3>
            <p>Selenium | Test Rail | Postman | OWASP ZAP | Apache JMeter | BrowserStack</p>
          </div>
        </div>
        <div className="warantycards">
          <div className="dotnetprocess1">
            <span className="processspan1">
              <img src={architecture} className="d11" />
            </span>
            <h3>Architecture</h3>
            <p>MVC | MVP | MVVM | Microservices</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      </div> */}
      {/* <div className="container native">
        <h2>React Native Road Map</h2>
        <div className="row angularframe">
          <div className="angularcontent">
            <span className="angularspan">
              <img src={backend} alt="Angular 1" />
            </span>
            <h3>Fantastic Framework</h3>
          </div>
          <div className="angularcontent">
            <span className="angularspan">
              <img src={frontend} alt="Angular 2" />
            </span>
            <h3>Real-time Application</h3>
          </div>
        </div>
        <div className="row angularframe1">
          <div className="angularcontent">
            <span className="angularspan">
              <img src={database} alt="Angular 3" />
            </span>
            <h3>Our Tech Stack</h3>
          </div>
          <div className="angularcontent">
            <img src={deployment} alt="Angular" />
          </div>
          <div className="angularcontent">
            <span className="angularspan">
              <img src={testing} alt="Angular 4" />
            </span>
            <h3>Two Way Data Binding</h3>
          </div>
        </div>
        <div className="row angularframe">
          <div className="angularcontent">
            <span className="angularspan">
              <img src={architecture} alt="Angular 5" />
            </span>
            <h3>Ease of Synchronization</h3>
          </div>
          <div className="angularcontent">
            <span className="angularspan">
              <img src={Angular6} className="angularimg" alt="Angular 6" />
            </span>
            <h3>REST Friendly</h3>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Dotnet;
