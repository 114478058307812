/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import Android from "./Images/Android.jpeg";
import { Image } from "react-bootstrap";
import logo11 from "./Images/arrow_outward.png";
import circle from "./Images/check_circle.png";
import IOSImage from "./Images/IOSImage.png";
import group from "./Images/iOS.jpg";
import group1 from "./Images/Group1.png";
import Card from "react-bootstrap/Card";
import WWW from "./Images/WWW.png";
import Rectangle from "./Images/Rectangle.png";
import Note from "./Images/Note.png";
import PC from "./Images/PC.png";
import pay from "./Images/pay.png";
import search from "./Images/search.png";
import search1 from "./Images/search1.png";
import lamp from "./Images/lamp.png";
import curve from "./Images/curve.png";
import goal from "./Images/goal.png";
import Modal1 from "./Modal1";
import iOSAPP from "./Images/iOSApp.jpg"
const IOS = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-6">
            <img src={group} width={100} />
           
          </div>
          <div className="col-md-6">
            <h2> iOS Development</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid nativecolor">
        <div className="container native">
          <h2>iOS Development Services</h2>
          <p>
            Bestowal Systems & Services, a leading iOS development company, is
            committed to creating top-notch apps for businesses, ensuring a
            seamless user experience on iPhone and iPad. Our focus lies in
            crafting swift, responsive, and power-efficient apps tailored to the
            latest iOS devices. With expertise in Swift and Objective-C, we
            offer personalized development services, including offline
            functionality, push notifications, and API integration. Our team
            follows best practices and employs cutting-edge tools to meet
            clients' unique needs. We provide guided development sessions
            covering essential concepts like design and performance Additionally, we offer marketing and promotion
            services to maximize app visibility. Adhering to agile approaches,
            we ensure timely 
            optimization. 
            {!isExpanded ? (
              <span className="dots">.....</span>
            ) : (
              <span className="more">
                project completion through transparent
            collaboration with clients. Trust us to build scalable, secure
            digital solutions that propel your business into the future through
            iOS application development.
              </span>
            )}
          </p>
          <button className="sapbtn" onClick={() => toggleExpand(0)}>
            {isExpanded ? "Read Less" : "Read More"}
            <span className="material-symbols-outlined">east</span>
          </button>
        </div>
      </div>
      <div className=" container android">
        <h2>Build Powerful Apps with Us</h2>
        <div className="row androidrow">
      <div className="col-md-8">
        <div className="androidframe">
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>Consultation on the Development</h4>
            </div>
            <p>
            Our iOS app developers help businesses determine if an iOS app is the right fit for their needs and select the optimal technology stack.</p>
          </div>
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>UI/UX Design</h4>
            </div>
            <p>
            Our team specializes in creating immersive and memorable iOS app designs that align with Apple's ecosystem, meeting customer expectations with experience-driven design.</p>
          </div>
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>Custom iOS Applications:</h4>
            </div>
            <p>
            Our iPhone app developers excel at crafting scalable iOS solutions tailored to businesses of all sizes and across various sectors, including startups.</p>
            </div>
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>iOS Software Testing:</h4>
            </div>
            <p>
            We prioritize security and performance in our iPhone app development. Using a blend of manual and AI-driven testing, we ensure a bug-free, secure application. </p>
          </div>
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>Cross-Platform Integration:</h4>
            </div>
            <p>
            We specialize in custom iPhone app development, ensuring seamless integration across multiple Apple devices to enhance your brand's presence across all user platforms.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <img src={iOSAPP}/>
      </div>
    </div>
      </div>
      
      <div className="fluid-container dgcolor">
        <div className="container process">
          <h2>Solutions for Your Needs</h2>

          <div className="processframe">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={search1} className="d111" />
              </span>
              <h4>iPhone app development services</h4>
            </div>
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={lamp} className="d111" />
              </span>
              <h4>Applications for Apple TV and smart devices</h4>
            </div>
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={curve} className="d111" />
              </span>
              <h4>iPad application development services</h4>
            </div>
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={goal} className="d111" />
              </span>
              <h4>Applications for Apple Watch</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IOS;
