/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import APO1 from "./Images/APO1.png";
import APO2 from "./Images/APO2.png";
import APO3 from "./Images/APO3.png";
const Blogdetails7 = () => {
  return (
    <>
      <div className="container blogdetails">
        <div className="article-header mt-5">
        <h1>SAP APO – HEURISTICS
        </h1>
        <div className="bloginfo">
            <p>
              <strong>Author Name:</strong>Avadhut Adake
            </p>
            <p>Publish Date: September 11, 2024</p>
          </div>
          <p>Heuristics are automated planning engines in SAP APO SNP that generate supply plans based on rules, not costs. They assume unlimited production capacity and use bucket-based planning.</p>
          <p>The SAP Supply Chain Management (SCM) comprises of several crucial components, and the Advanced Planning and Optimization (APO) module is one of them. APO is responsible for managing various business processes such as Demand Planning, Supply Network Planning (SNP), Production Planning/Detailed Scheduling (PP/DS), and Global Available-to-Promise (GATP).</p>
          <br/>
          <p>In this document, we will see an overview about:</p>
          <p>
            <ul>
                <li>What are different types of Planning engines</li>
                <li>What is Heuristic</li>
                <li>Factors considered in Heuristic run</li>
                <li> Types of Heuristics</li>
                <li> Low level code</li>
                <li>Working of Heuristics in Background</li>
            </ul>
          </p>
          <p><b>1. Different types of Planning Engine: -</b></p><br/>
          <p>APO SNP provides different programming algorithms to solve the supply planning situations. Below are different types of planning engines available in APO SNP: -</p>
          <p>Heuristic – Rule based</p>
          <p>- Sourcing based on quota arrangement</p>
          <p>- No consideration of constraints</p>
          <p>- Capacity levelling as additional planning step</p>
          <br/>
          <p>Optimizer – Cost based </p>
          <p>- Optimization of all costs (production, transportation, storage, handling, late delivery cost, etc.)</p>
          <p>- Considers all capacity constraints.</p>
          <p>- SNP optimizer determines the optimal solution based on predefined costs for supply alternatives and demands.</p><br/>
          <p>Capable to Match (CTM) – Priority based</p>
          <p>- Sourcing based on priorities and quota arrangement</p>
          <p>- Considers production capacity constraints</p>
          <p>- CTM considers predefined supply categories and demand priorities.</p><br/><br/>
          <p><b>2. What is Heuristic: - </b></p>
          <p>Definition: -</p><br/>
          <p><b>Heuristic is a planning engine used in Advanced Planning and Optimization for supply planning network, which plans for a constant flow of material throughout the supply network and inform if the material is to be procured or produced in each location.</b></p><br/>
          <p>Heuristic planning run processes each location in a particular sequence and determines all of the requirements in each location. The heuristic run groups all demands for a given product and location combination in one bucket.</p><br/>
          <p>The heuristic run determines valid source of supply and corresponding quantity based on Quota arrangement or procurement priority maintained in Transportation Lane and production process model (PPM) or production data structures (PDS).</p>
          <p>Business Objective – To create a feasible plan with few bottlenecks resource.</p>
          <p>Capacity – Heuristic run based on assumption that the capacity is infinite.</p>
          <p>Planning approach – Bucket (Period-based) planning.</p><br/>
          <p>3. Factors considered in Heuristic run: -</p><br/>
          <p>Following are the different factors which are considered while heuristic run-in background:</p>
          <p>
            <ul>
                <li>Location product</li>
                <li> Low level code of the location product</li>
                <li>Valid Production process model (PPM)</li>
                <li>Valid Transportation Lane</li>
                <li>Quota arrangement or Procurement priorities</li>
                <li>Calendar</li>
                <li>Lot size rules</li>
            </ul>
          </p>
          <p>Other considerations</p>
          <p>
            <ul>
                <li>The heuristic run considers all demand for a given product/location combination within one period as one demand.</li>
                <li>The heuristic planning sequence is governed by the low-level codes of the location products.</li>
                <li>The initial solution produced by the heuristic run may not be feasible because the system assumes that capacity is infinite. The planner must then adjust the plan by leveling resource capacity.</li>
            </ul>
          </p>
          <p><b>4.Types of Heuristics: -</b></p>
          <p>There are three types of Heuristic available in APO SNP for the planning purpose.</p>
          <p><b>Location Heuristics</b></p>
          <p>
            <ul>
                <li>- Planning is done at a single selected location and for selected products with selected part of the model to be planned</li>
                <li>- It propagates the requirements to sources at only one level.</li>
                <li> Dependent demand is neither fulfilled nor fully propagated throughout the supply chain.</li>
                <li>The result is generation of stock transport requisitions, planned orders and purchase requisitions.</li>
                <li>Transaction code is /N/SAPAPO/SNP01 and then select Location (Heuristic).</li>
            </ul>
          </p>
          <img src={APO1}/>
          <p><b>Network Heuristics </b></p>
          <p>
            <ul>
                <li>The system plans for the entire network that is at all specified locations in supply chain on which the specified product is assigned. If you have Product A assigned at Locations L101, L102 and L103. Then the system plans the Product A at all three locations in the network.</li>
                <li>The system explodes dependent demand for one BOM level at the first location encountered in the planning direction. This will generate stock transport requisitions, planned order and purchase requisitions.</li>
                <li>Transaction code is /N/SAPAPO/SNP01 and then select Network (Heuristic).</li>
            </ul>
          </p>
          <img src={APO2}/>
          <p>Multilevel Heuristics </p>
          <p>
            <ul>
                <li>  The system plans for all products specified at all locations, whether they are finished, intermediary, or purchased goods from the highest level down to the lowest BOM level.</li>
                <li>Multi-level heuristics is performed across all locations to which selected products are assigned, as well as across all locations to which dependent products are assigned.</li>
                <li>For example, if product A needs product B to be produced, and product B is made of product C, then multi-level heuristic plans all 3 products when selecting product, A only.</li>
                <li>Transaction code is /N/SAPAPO/SNP01 and then select Network (Heuristic)</li>
            </ul>
          </p><br/>
          <p><b>5. Low level code: -</b></p>
          <p>
            <ul>
                <li>The low-level code specifies the bill of material (BOM) level and supply chain location at which a location product is situated.</li>
                <li>The SNP heuristics needs this information to be able to determine the correct planning sequence for the location products, thus ensuring that demands are fulfilled correctly.</li>
                <li>The system uses the product’s position in the bill of material and the location assigned to the product to determine low-level code.</li>
                <li>The highest low-level code (0) is attributed to the finished product assigned to the final location in the supply chain. (say customer location)</li>
                <li> The second highest low-level code (1) is attributed to finished product at distribution center and so on.</li>
                <li>The system assigns low-level codes to the individual components at the different BOM levels in the same.</li>
                <li> The SNP heuristic then plans the location products in the low-level code sequence, first planning the location product with low-level code 0 and so on.</li>
                <li>  Transaction code /N/SAPAPO/SNPLLC</li>
            </ul>
          </p>
          <img src={APO3}/>
          <p>6. Working of Heuristic in Background: -</p><br/>
          <p><b>Step 1. Calculates Target stock level</b></p>
          <p>Target stock level = Maximum Stock level + Safety stock</p><br/>
          <p><b>Step 2. Net requirement calculation</b></p>
          <p>Net requirement = Total demand + Target stock level – Planned stock level</p><br/>
          <p><b>Step 3. Source of supply</b></p>
          <p>System will check for source of supply via Quota arrangement. If no quota, then procurement priorities.</p><br/>
          <p><b>Step 4. Lot sizing rules</b></p>
          <p>Then lot sizing rules are applied to the net requirements such as minimum lot, maximum lot, rounding value etc.</p><br/>
          <p><b>Step 5. Procurement type</b></p>
          <p> System will determine if the product is to be procured from external location or to be produced in-house depending upon the procurement type maintained in master data</p>
          <p><b>Step 6. Validity of source of supply</b></p>
          <p> Validity of source of supply such as validity of PDS or PPM and Transportation Lane is checked. </p>
          <p><b>Conclusion</b></p>
          <p>
            <ul>
                <li>In SAP APO SNP, Heuristic is a planning engine that is used to automatically generate plans and schedules.</li>
                <li>Based on type of heuristic, it will generate a supply plan to meet the planning needs.</li>
                <li>These heuristics are designed to efficiently solve specific planning problems within the supply chain. They use rules and algorithms to quickly generate feasible plans.</li>
            </ul>
          </p><br/><br/>
          <p>
            <b>Created by Avadhut Adake</b>
          </p>
        </div>
      </div>
    </>
  );
};

export default Blogdetails7;
