import React, { useState } from "react";
import { Image } from "react-bootstrap";
import sap from "./Images/SAP.png";
import saphana from "./Images/SAPhana2.png";
import logo11 from "./Images/arrow_outward.png";
import circle from "./Images/check_circle.png";
import SAP1 from "./Images/SAP1.png";
import saphana1 from "./Images/saphana1.png";
import Modal1 from "./Modal1";
import { Modal, Button } from "react-bootstrap";
import HANA from "./Images/s4hana.jpg";
import human from "./Images/s4hana_human.jpg"
const SAPHANA = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <div className="container saphana">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={sap} width={400}/>
          </div>
          <div className="col-md-8">
            <h2>SAP S/4HANA Implementation</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid sapcolor">
        <div className="container sapinfo">
        <h2>What Is SAP S/4HANA?</h2>
          <div className="row">
          <div className="col-md-7">
         
          <p>
            SAP S/4HANA is a suite of best-in-class enterprise resource planning
            (ERP) software offered by SAP. It has been completely redesigned to
            take advantage of the capabilities of the HANA database. Previous
            versions of SAP ERP were designed to run on third-party databases
            such as Oracle or SQL Server, while S/4HANA is specifically built to
            run on HANA in order to take advantage of the database's speed and
            analytical capabilities. As a result, S/4HANA is able to
            significantly improve the speed of various business processes,
            provide real-time reporting, and better support a range of use cases
            related to automation, decision-making, predictive analysis, the
            Internet of Things (IoT), and other modern business needs. S/4 HANA
            is a next- generation business suite meant to make digital
                transformation easier
            {!isExpanded ? (
              <span className="dots">.....</span>
            ) : (
              <span className="more">
               for businesses. The intelligent suite features a customizable
                user experience with SAP Fiori and is based on the powerful
                in-memory database SAP HANA. When performing a SAP installation
                project, SAP implementation services define a service offered by
                a team of developers to assist firms in creating an effective
                work environment for all users by decreasing mistakes,
                redundancies, and data loss.
              </span>
            )}
          </p>
          <button className="sapbtn" onClick={() => toggleExpand(0)}>
            {isExpanded ? "Read Less" : "Read More"}
            <span className="material-symbols-outlined">east</span>
          </button>
          </div>
            <div className="col-md-5">
              <img src={HANA}/>
            </div>
          </div>
        </div>
      </div>
     
      <div className="container sapinfo">
        <h2>How Does S/4HANA Differ From Suite On HANA?</h2>
        <div className="row aboutusrow">
          <div className="col-md-5">
            <p>
              The fundamental platform is Suite on HANA, also known as HANA
              Business Suite. The HANA platform is used by Corporate Suite,
              which offers solutions for most business operations. S/4HANA is
              the platform that you should be using by 2025. Let's examine the
              additional features that SAP has included with S/4HANA.
              <br /> S/4 HANA is a next-generation business suite meant to make
              digital transformation easier for businesses. The intelligent
              suite features a customizable user experience with SAP Fiori and
              is based on the powerful in-memory database SAP HANA.
              <br /> When performing a SAP installation project, SAP
              implementation services define a service offered by a team of
              developers to assist firms in creating an effective work
              environment for all users by decreasing mistakes, redundancies,
              and data loss.
            </p>
          </div>
          <div className="col-md-5">
            <img
              src={saphana}
              width={500}
              alt="SAP HANA"
              onClick={handleShow}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>

        <Modal
          show={showModal}
          onHide={handleClose}
          size="lg"
          centered
          className="ModalImage"
        >
          <Modal.Header closeButton>
            <Modal.Title>SAP HANA</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={saphana} width="100%" alt="SAP HANA" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="container-fluid rpacolor">
      <div className=" container sapinfo">
        <h2>Why Choose Bestowal for SAP S/4HANA Services?</h2>
        <div className="row androidrow">
          <div className="col-md-8">
            <div className="androidframe">
              <div className="CaptioN0">
                <div className="androidinfo">
                  <img src={circle} className="circle-img" />
                  <h4>Convergent Engagement Models</h4>
                </div>
                <p>
                  Our flexible engagement models, including project-based,
                  fixed-cost, and time-and-material, enable businesses to
                  achieve maximum business value in terms of cost, capacity, and
                  speed.
                </p>
              </div>
              <div className="CaptioN0">
                <div className="androidinfo">
                  <img src={circle} className="circle-img" />
                  <h4>Inventory Planning</h4>
                </div>
                <p>
                  We assist in establishing optimal inventory targets to
                  maintain high customer service levels while maximizing
                  profitability.
                </p>
              </div>
              <div className="CaptioN0">
                <div className="androidinfo">
                  <img src={circle} className="circle-img" />
                  <h4>Cloud Deployment</h4>
                </div>
                <p>
                  Our subscription-based model reduces upfront costs and
                  provides a predictable budgeting structure.
                </p>
              </div>
              <div className="CaptioN0">
                <div className="androidinfo">
                  <img src={circle} className="circle-img" />
                  <h4>Scenario Planning</h4>
                </div>
                <p>
                  Utilize scenario planning to compare different alternatives
                  and make informed decisions.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <img src={human}/>
          </div>
        </div>
        <button onClick={handleOpenModal}>
          Get In Touch <span className="material-symbols-outlined">east</span>
        </button>
        {isModalOpen && <Modal1 onClose={handleCloseModal} />}
      </div>
      </div>
        <div className="container saphana">
          <div className="row aboutusrow">
            <div className="col-md-6">
              <h2>Benefits of SAP S/4HANA</h2>
            </div>
            <div className="col-md-6">
              <img src={saphana1} width={600}/>
            </div>
          </div>
          <div className="row rparow">
            <div className="rparow1">
              <div className="col-md-12">
                <div className="rpainfo1">
                  <div className="saphanainfo1">
                    <h4>Efficiency in Accounting and Finance:</h4>
                    <p>
                      Before deploying S/4HANA, companies operated in siloed
                      departments that required manual consolidation at the end
                      of each financial period. They often used third-party
                      reporting tools or Microsoft Excel to enhance reporting
                      capabilities. With S/4HANA, customers consolidated all
                      accounting and financial activities into a single instance
                      of the global general ledger. This transition democratized
                      reporting, allowing users to create custom reports
                      independently.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="rpainfo1">
                  <div className="saphanainfo1">
                    <h4>Improved planning:</h4>
                    <p>
                      Previously, planning was fragmented across departments and
                      relied on Excel and legacy ERP systems. Some businesses
                      faced productivity losses due to lengthy planning
                      processes. S/4HANA streamlined planning cycles, enabling
                      continuous operations without disruptions. Centralized
                      data eliminated siloed planning, facilitating coordinated
                      operational strategies across departments and providing
                      holistic visibility into operations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="rpainfo1">
                  <div className="saphanainfo1">
                    <h4>Reduced IT Costs:</h4>
                    <p>
                      Outdated ERP systems incurred high maintenance costs and
                      required significant resources for customization and
                      extension development. Transitioning to S/4HANA reduced
                      these costs by eliminating many customizations and
                      outsourcing maintenance tasks. This allowed businesses to
                      redirect resources to more value-added activities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="rpainfo1">
                  <div className="saphanainfo1">
                    <h4>Enhanced Cross-Functional Coordination:</h4>
                    <p>
                      Centralized data in S/4HANA enables seamless planning
                      across departments and provides visibility into quality
                      assurance and manufacturing execution. Mobile capabilities
                      allow users to input data and monitor operations in
                      real-time, enhancing responsiveness and reducing
                      inefficiencies caused by information delays.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
    </>
  );
};

export default SAPHANA;
