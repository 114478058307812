/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useParams } from "react-router-dom";
import periodfactor1 from "./Images/periodfactor1.png";
import periodfactor2 from "./Images/periodfactor2.png";
import periodfactor3 from "./Images/periodfactor3.png";
import periodfactor4 from "./Images/periodfactor4.png";
import periodfactor5 from "./Images/periodfactor5.png";
import periodfactor6 from "./Images/periodfactor6.png";
import periodfactor7 from "./Images/periodfactor7.png";
import periodfactor8 from "./Images/periodfactor8.png";
import periodfactor9 from "./Images/periodfactor9.png";
import periodfactor10 from "./Images/periodfactor10.png";
const BlogDetail = () => {
  const { id } = useParams(); // Extract blog id from URL

  return (
    <>
      <div className="container blogdetails">
        <div className="article-header mt-5">
          <span class="badge bg-primary">SAP</span>
          <h1>Period Weight Factor</h1>
          <div className="bloginfo">
            <p>
              <strong>Author Name</strong>:Siddamma Gudda
            </p>
            <p>Publish Date: September 11, 2024</p>
          </div>
        </div>

        <div class="article-content mt-4">
          <p>
            The disaggregation process adheres to the standard principles of
            proportional disaggregation, with the exception that the
            proportionality factors are determined by the attribute designated
            as the period weight factor.
          </p>
          <br />
          <br />
          <p>
            The 'Period Weight Factor' field in the key figure configuration UI
            serves a specific purpose in the Week-to-Month scenario, which was
            implemented in IBP 6.1. This scenario offers a modeling approach
            that enables a seamless aggregation and disaggregation of key figure
            values between calendar weeks and months. To utilize this scenario,
            you need to follow the following steps:
            <br />
            <br />
            <ol>
              <li>Define an attribute for the period weight factor</li>
              <li>Define a time profile that has technical weeks.</li>
              <li>
                Assign the period weight factor attribute to the technical week
                time profile level.
              </li>
              <li>
                Upload time profile periods with the period weight factor (e.g.
                reflecting calendar or working days).
              </li>
              <li>
                Assign the period weight factor attribute to the relevant
                planning levels.
              </li>
              <li>
                Assign the period weight factor attribute to the relevant key
                figures
              </li>
            </ol>
          </p>
          <br />
          <br />
          <br />
          <p>
            <b>Procedure for period weight factor</b>
          </p>
          <br />
          <p>
            <b>Define the period weight factor attribute</b>
          </p>
          <p>
            In the <b>Attributes</b> app, define one or more attributes that
            represent the period weight factor. Typically, the number of
            workdays or calendar days is used as the period weight.
          </p>
          <br />
          <p>Specify INTEGER as the data type of the attribute.</p>
          <img src={periodfactor1} />
          <p>
            <strong>
              Assign the period weight factor attribute you created to the
              technical week level of the selected time profile.
            </strong>
          </p>
          <br />
          <p>
            Assign the period weight factor attribute you created to the
            technical week level of the selected{" "}
            <strong>time profile app.</strong>
          </p>
          <img src={periodfactor2} />
          <br />
          <p>
            <b>Activate your time profile.</b>
          </p>
          <p>You can activate your time profile in the Time Profiles app.</p>
          <p>
            <b>
              Upload time periods with period weight factors into the time
              profile.
            </b>
          </p>
          <p>
            Upload time periods with period weight factors into the time
            profile.
          </p>
          <img src={periodfactor3} />
          <br />
          <p>
            <b>CASE 1: TO CHECK THE WEEK WEIGHT FACTOR</b>
          </p>
          <p>
            <b>
              Assign the period weight factor attribute to the relevant planning
              levels.
            </b>
          </p>
          <br />
          <br />
          <p>
            If you want to read and write key figure values in both (calendar)
            weeks and months, select the technical week time profile level as
            the root in the base planning levels used in the given key figures.
            Also assign the period weight factor to the base planning level of
            the key figure. You do this on the Planning Levels tab in the
            Planning Areas app.
          </p>
          <img src={periodfactor4} />
          <br />
          <p>
            <b>
              Assign the period weight factor attribute to the relevant key
              figures.
            </b>
          </p>
          <br />
          <p>
            You must specify the period weight factor for each key figure whose
            values you would like to access in both weeks and months. To do
            this, go to the Key Figures tab in the Planning Areas app, and
            select the attribute you created for the period weight factor.
          </p>
          <img src={periodfactor5} /> <br />
          <p>
            <b>Activate the planning area for your changes to take effect.</b>
          </p>
          <p>
            <b>Results In WEB UI:</b>
          </p>
          <br />
          <p>
            Let us examine a case in point, where the sales quantities are
            stored at the calendar week level.We can observe the breakdown at
            the technical week level based on the week weights provided in the
            time profile.{" "}
          </p>
          <img src={periodfactor6} /> <br />
          <img src={periodfactor7} /> <br />
          <p>
            Take a glance at the time profile to see the assigned weightage. For
            TW05a Jan 2022, we have allocated a weightage of 1, while for TW05b
            Feb 2022, the weightage is 6.
          </p>
          <br />
          <p>
            The total value of 500 for the calendar week is divided into TW05a
            Jan 2022 and TW05b Feb 2022. In other words, 500 is disaggregated
            into 71 for TW05a Jan 2022 (500/7) and 429 for TW05b Feb 2022 (6
            parts out of 7).{" "}
          </p>
          <p>
            <b>Time profile for week weight:</b>
          </p>
          <img src={periodfactor8} /> <br />
          <p>
            <b>CASE 2: TO CHECK THE AGREEGATION AT MONTH LEVEL</b>
          </p>
          <br />
          <p>
            <b>
              Period weight factor maintained at month level (based on Days)
            </b>
          </p>
          <img src={periodfactor8} /> <br />
          <p>
            <b>
              ﻿If you refer to the table below, you will notice that the
              quantities in January and February differ. This is due to the fact
              that January has 31 days, while February only has 28 days (or 29
              in a leap year).
            </b>
          </p>
          <p>
            <b>
              Consequently, when aggregating the data at the month level, the
              values will vary. Based on our calculations, the values provided
              are at the daily level (71.42 per day). In January, with 31 days,
              the total is 2071 (31 * 71.42), while in February, with 28 days,
              the total is 2044 (28 * 71.42).
            </b>
          </p>
          <img src={periodfactor10} /> <br />
          <p>
            <b>Conclusion</b>
          </p>
          <br />
          <p>
            The concept behind this scenario involves introducing a new time
            profile level for Technical Weeks, which divides calendar weeks that
            span across two months. By doing so, data from calendar weeks and
            months can be consistently aggregated and disaggregated at this
            Technical Weeks level.
            <br />
            Since Technical Weeks can have varying lengths in terms of calendar
            or working days, a period weight factor is utilized as a
            proportional factor for disaggregation in the absence of data.
          </p>
          <p>
            <b>Created By: Siddamma Gudda</b>
          </p>
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
