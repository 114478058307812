/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import {
  FaEnvelope,
  FaPhone,
  FaUser,
  FaBuilding,
  FaList,
  FaRegComment,
} from "react-icons/fa";
import {
  Carousel,
  Button,
  Card,
  Row,
  Col,
  Container,
  Image,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./App.css";
import Bestowal from "./Images/Bestowal.png";
import background from "./Images/img-hero.jpg";
import arrow from "./Images/arrow1.jpg";
import bag from "./Images/background.jpeg";
import Mobile from "./Images/Mobile.jpg";
import Web from "./Images/Web.jpg";
import { FaArrowRight } from "react-icons/fa";
import software from "./Images/Software.png";
import contact from "./Images/img-contact.png";
import { FaCaretDown } from "react-icons/fa";
import TM from "./Images/TM.png";
import Infosys from "./Images/Infosys.png";
import GC from "./Images/gitacloud.png";
import Dynpro from "./Images/dynpro.png";
import sai from "./Images/sai.png";
import sapture from "./Images/saptrue.png";
import wudza from "./Images/wudza.png";
import HIC from "./Images/HIC.png";
import ats from "./Images/ats.png";
import Aysmtech from "./Images/aysmtech.png";
import crave from "./Images/crave.png";
import acnovate from "./Images/acnovate.png";
import DG from "./Images/daynilgroup.png";
import crafsol from "./Images/crafsol.png";
import medha from "./Images/medha.png";
import yash from "./Images/yash.png";
import ST from "./Images/ST.png";
import rudersoft from "./Images/rudersoft.png";
import product from "./Images/img-Product.png";
import process from "./Images/img-Process.png";
import people from "./Images/img-People.png";
import SAP from "./Images/SAP_Services.jpg";
import Other from "./Images/other.jpg";
import { CgProfile } from "react-icons/cg";
import { FaHandHoldingUsd } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa6";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { SlNote } from "react-icons/sl";
import company from "./Images/ic-company.png";
import email from "./Images/ic-email.png";
import message from "./Images/ic-message.png";
import phone from "./Images/ic-phone.png";
import services from "./Images/ic-services.png";
import user from "./Images/ic-user.png";
import map from "./Images/map.png";
import downarrow from "./Images/downarrow.png";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import All from "./Images/img-body.jpg";
import bags from "./Images/bag.jpg";
import { useRef } from "react";
import Linksap from "./Images/Linksap.png";
import logix from "./Images/logix.png";
import Oxford from "./Images/Oxford.png";
import Hays from "./Images/Hays.png";
import SiyaTech from "./Images/SiyaTech.png";
import IBPService from "./Images/IBPService.jpg";
import HanaServices from "./Images/HanaService.jpg";
import MonitoringService from "./Images/MonitoringService.jpg";
import NodeJsService from "./Images/NodeJsService.jpg";
import JavaService from "./Images/JavaService.jpg";
import ReactjsService from "./Images/ReactjsService.jpg";
import DotNetService from "./Images/DotNetService.jpg";
import PHPService from "./Images/PHPService.jpg";
import AngularService from "./Images/AngularService.jpg";
import WordpressService from "./Images/WordpressService.jpg";
import Arrow from "./Images/Arrow.jpeg";
import SAPOffers from "./Images/SAP.png";
import bg1 from "./Images/bg1.jpg";
import bg2 from "./Images/bg2.jpg";
import bg3 from "./Images/bg3.jpg";
import bg4 from "./Images/bg4.jpg";
// import HanaService from "./Images/HanaService.jpg";
// import HanaService from "./Images/HanaService.jpg";
const PhoneErrorPopup = ({ message, inputRef }) => {
  return (
    <div
      className="phone-error-popup"
      style={{
        position: "absolute",
        top: inputRef.current.offsetTop + inputRef.current.offsetHeight,
        left: inputRef.current.offsetLeft,
        backgroundColor: "#f8d7da",
        padding: "5px",
        borderRadius: "5px",
        border: "1px solid #f5c2c7",
        color: "#842029",
        zIndex: 1000,
        whiteSpace: "nowrap",
      }}
    >
      {message}
    </div>
  );
};
const Test = () => {
  const [isExpanded, setIsExpanded] = useState([false, false, false, false]);

  const toggleExpand = (index) => {
    setIsExpanded((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
  const carouselRef = useRef(null);

  const handlePrev = () => {
    carouselRef.current.prev();
  };

  const handleNext = () => {
    carouselRef.current.next();
  };
  const [formData, setFormData] = useState({
    name: "",
    service: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });

  const [phoneError, setPhoneError] = useState("");
  const phoneInputRef = React.useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "phone") {
      setPhoneError("");
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const { phone } = formData;
    if (!/^\d{10}$/.test(phone)) {
      setPhoneError("Please enter a valid 10-digit phone number.");
      return;
    }

    try {
      const response = await fetch("https://blog-backend-ivfv.onrender.com/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }

      const result = await response.json();
      console.log("Form submitted successfully:", result);
      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form. Please try again later.");
    }
  };
  return (
    <>
      <div className="custom-carousel-container">
        <span
          className="material-symbols-outlined custom-prev"
          onClick={handlePrev}
        >
          arrow_back_ios
        </span>

        <Carousel ref={carouselRef} controls={false} indicators={false}>
          {/* First Slide */}
          <Carousel.Item>
            <img
              src={bg1} // Ensure this is the correct path
              style={{ height: "350px" }}
              className="carouselimg"
              alt="First slide"
            />
            <div className="overlay"></div>

            {/* Text for First Slide */}
            <div className="carousel-heading">
              <h1>SAP IBP</h1>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={bg2} // Ensure this is the correct path
              style={{ height: "350px" }}
              className="carouselimg"
              alt="Second slide"
            />
            <div className="overlay"></div>

            {/* Different Text for Second Slide */}
            <div className="carousel-heading">
              <h1>SAP S/4HANA Implementation</h1>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={bg3} // Ensure this is the correct path
              style={{ height: "350px" }}
              className="carouselimg"
              alt="Second slide"
            />
            <div className="overlay"></div>
            {/* Different Text for Second Slide */}
            <div className="carousel-heading">
              <h1>SAP S/4 Monitoring & Support</h1>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={bg4} // Ensure this is the correct path
              style={{ height: "350px" }}
              className="carouselimg"
              alt="Second slide"
            />
            <div className="overlay"></div>
            {/* Different Text for Second Slide */}
            <div className="carousel-heading">
              <h1>React JS Development</h1>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={bg2} // Ensure this is the correct path
              style={{ height: "350px" }}
              className="carouselimg"
              alt="Second slide"
            />
            <div className="overlay"></div>

            {/* Different Text for Second Slide */}
            <div className="carousel-heading">
              <h1>Java Development</h1>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={bg3} // Ensure this is the correct path
              style={{ height: "350px" }}
              className="carouselimg"
              alt="Second slide"
            />
            <div className="overlay"></div>
            {/* Different Text for Second Slide */}
            <div className="carousel-heading">
              <h1>Dot Net Development</h1>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={bg4} // Ensure this is the correct path
              style={{ height: "350px" }}
              className="carouselimg"
              alt="Second slide"
            />
            <div className="overlay"></div>
            {/* Different Text for Second Slide */}
            <div className="carousel-heading">
              <h1>PHP Development</h1>
            </div>
          </Carousel.Item>
        </Carousel>

        <span
          className="material-symbols-outlined custom-next"
          onClick={handleNext}
        >
          arrow_forward_ios
        </span>
      </div>

      <div className="container services1">
        {/* Row 1 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-5 order-1 order-md-1">
            <img
              src={IBPService}
              className="img-fluids"
              alt="SAP IBP"
              title="SAP IBP"
            />
          </div>
          <div className="col-md-7 order-2 order-md-2 text-start">
            <h2>SAP IBP</h2>
            <p>
              SAP IBP allows you to make strategic and operational data-driven
              decisions in all aspects of your supply chain. Don’t have an SCM
              solution yet? Consider moving to IBP and S/4HANA, with Sales and
              Operations planning as a possible starting point.
            </p>
            <Link to="/sap" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
        </div>

        {/* Row 2 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-7 order-2 order-md-1 text-end">
            <h2>SAP S/4HANA Implementation</h2>
            <p>
              S/4 HANA is a next-generation business suite meant to make digital
              transformation easier for businesses. The intelligent suite
              features a customizable user experience with SAP Fiori and is
              based on the powerful in-memory database SAP HANA.
            </p>
            <Link to="/saphana" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
          <div className="col-md-5 order-1 order-md-2">
            <img
              src={HanaServices}
              className="img-fluids"
              alt="SAP S/4Hana Implementation"
              title="SAP S/4Hana Implementation"
            />
          </div>
        </div>

        {/* Row 3 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-5 order-1 order-md-1">
            <img
              src={MonitoringService}
              className="img-fluids"
              alt="SAP S/4 Monitoring and Support"
              title="SAP S/4 Monitoring and Support"
            />
          </div>
          <div className="col-md-7 order-2 order-md-2 text-start">
            <h2>SAP S/4 Monitoring & Support</h2>
            <p>
              Monitoring past and current information about the performance of
              the SAP HANA database is important to prevent performance issues
              and for root-cause analysis of problems.
            </p>
            <Link to="/monitoring" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
        </div>

        {/* Row 4 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-7 order-2 order-md-1 text-end">
            <h2>React Native Development</h2>
            <p>
              At Bestowal, our dedicated React Native development team leverages
              the latest React JavaScript framework to create high-quality
              native apps for both Android and iOS. Our skilled designers and
              developers collaborate closely to build seamless cross-platform
              applications using React Native.
            </p>
            <Link to="/native" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
          <div className="col-md-5 order-1 order-md-2">
            <img
              src={All}
              className="img-fluids"
              alt="React Native"
              title="React Native"
            />
          </div>
        </div>

        {/* Row 5 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-5 order-1 order-md-1">
            <img
              src={NodeJsService}
              className="img-fluids"
              alt="NodeJS Development"
              title="NodeJS Development"
            />
          </div>
          <div className="col-md-7 order-2 order-md-2 text-start">
            <h2>Node JS Development</h2>
            <p>
              As a specialized Node.js development company, we provide
              comprehensive application development and maintenance services,
              ensuring exceptional reliability and scalability for your business
              needs. Node.js is an open-source, cross-platform runtime
              environment known for its speed and efficiency in developing
              robust server tools and network applications.
            </p>
            <Link to="/node" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
        </div>

        {/* Row 6 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-7 order-2 order-md-1 text-end">
            <h2>Java Development</h2>
            <p>
              In today’s tech-driven world, applications play a crucial role in
              various tasks across different platforms and operating systems.
              Java stands out as a versatile solution, enabling developers to
              create applications for both desktop and mobile devices
              seamlessly.
            </p>
            <Link to="/java" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
          <div className="col-md-5 order-1 order-md-2">
            <img
              src={JavaService}
              className="img-fluids"
              alt="Java Development"
              title="Java Development"
            />
          </div>
        </div>

        {/* Row 7 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-5 order-1 order-md-1">
            <img
              src={AngularService}
              className="img-fluids"
              alt="AngularJS Development"
              title="AngularJS Development"
            />
          </div>
          <div className="col-md-7 order-2 order-md-2 text-start">
            <h2>Angular JS Development</h2>
            <p>
              Our developers excel in building rich user interfaces and creating
              responsive single-page web applications using AngularJS. To
              leverage the full potential of AngularJS, hire our AngularJS
              developers today. We offer expertise in:  AngularJS Development
              with server-side technologies like ASP.NET, JSP/JAVA, PHP, Ruby on
              Rails, etc.  Portal Development  Web Application Development 
              E-Commerce and Shopping Cart Development  Plug-in Development
            </p>
            <Link to="/angular" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
        </div>

        {/* Row 8 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-7 order-2 order-md-1 text-end">
            <h2>React JS Development</h2>
            <p>
              React.JS is powering 45% of websites on the internet. Many big
              brands like Netflix, Facebook, Instagram etc. Here you will find
              highly experienced React JS Developers that have extensive
              knowledge and intense practice in utilizing the world’s most
              popular JavaScript Framework- ReactJS. Our React Developers are
              highly experienced technical-beings with great know-how knowledge
              of React JS Development.
            </p>
            <Link to="/reactjs" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
          <div className="col-md-5 order-1 order-md-2">
            <img
              src={ReactjsService}
              className="img-fluids"
              alt="ReactJS Development"
              title="ReactJS Development"
            />
          </div>
        </div>

        {/* Row 9 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-5 order-1 order-md-1">
            <img
              src={DotNetService}
              className="img-fluids"
              alt="DotNet Development"
              title="DotNet Development"
            />
          </div>
          <div className="col-md-7 order-2 order-md-2 text-start">
            <h2>Dot Net Development</h2>
            <p>
              Bestowal Systems and Services Private Limited is your ideal choice
              for .NET development. We specialize in creating robust,
              multi-functional web applications, .NET-based applications, and
              both internet and intranet websites. With years of experience and
              a commitment to the latest Microsoft technologies, our experts
              deliver practical, up-to-date solutions across various industries.
            </p>
            <Link to="/dotnet" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
        </div>

        {/* Row 10 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-7 order-2 order-md-1 text-end">
            <h2>PHP Development</h2>
            <p>
              We use PHP Language in all the web development because PHP is an
              open-source server-side scripting language which can be easily
              embedded into the HTML and CSS.PHP installation and configuration
              is very easy so it doesn’t make any problem in the PHP
              Development. PHP provides a high security which doesn’t require
              big changes to be done during the project development that why it
              is chosen by the many developers and the companies.
            </p>
            <Link to="/php" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
          <div className="col-md-5 order-1 order-md-2">
            <img
              src={PHPService}
              className="img-fluids"
              alt="PHP Development"
              title="PHP Development"
            />
          </div>
        </div>

        {/* Row 11 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-5 order-1 order-md-1">
            <img
              src={WordpressService}
              className="img-fluids"
              alt="Wordpress Development"
              title="Wordpress Development"
            />
          </div>
          <div className="col-md-7 order-2 order-md-2 text-start">
            <h2>WordPress Development</h2>
            <p>
              Whether you're launching a new site or revamping an existing one,
              our team delivers high-performance, customized WordPress solutions
              tailored to your needs. We offer custom themes with unique,
              responsive designs that perfectly capture your brand's essence.
              Our expertise in plugin development ensures that your site
              functions optimally with tailored features, and we provide
              reliable maintenance to keep everything running smoothly.
            </p>
            <Link to="/wordpress" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="container Journey">
        <h1>Our Clients Journey Map</h1>
      </div>
      <div className="container-fluid map">
        <img src={map} alt="client journey map" title="Our Clients" />
      </div>
      <div className="container client-section">
        <h2 className="client-heading">Our Clients</h2>
        <p className="client-description">
          Empowering Success Stories: Meet Our Valued Clients
        </p>
      </div>

      <div className="container-fluid marquee">
        <marquee behavior="scroll" direction="start" scrollamount="10">
          <a
            href="https://www.techmahindra.com/en-in/?f=1415427774"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={TM}
              alt="Tech Mahindra"
              title="Tech Mahindra"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.infosys.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Infosys}
              alt="Infosys"
              title="Infosys"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.gitacloud.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={GC}
              alt="Gita Cloud"
              title="Gita Cloud"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.dynproindia.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Dynpro}
              alt="Dynpro"
              title="Dynpro"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.acnovate.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={acnovate}
              alt="Acnovate"
              title="Acconvate"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.sapture.com.au/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={sapture}
              alt="sapture"
              title="Sapture"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.wudza.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={wudza}
              alt="Wudza"
              title="Wudza"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://hicglobalsolutions.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={HIC}
              alt="HIC Global"
              title="HIC Global"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.ats-global.com/services/ats-consulting/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ats}
              alt="ATS Global"
              title="ATS Global"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://aasymtech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Aysmtech}
              alt="Aysmtech"
              title="Aysmtech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.craveinfotech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={crave}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>

          <a
            href="https://daynilgroup.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={DG}
              alt="Daynilgroup"
              title="Daynilgroup"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://crafsol.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={crafsol}
              alt="Crafsol"
              title="Crafsol"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a href="https://medha.eu/" target="_blank" rel="noopener noreferrer">
            <img
              src={medha}
              alt="Medha Partners"
              title="Medha Partners"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.yash.com/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={yash}
              alt="Yash Technology"
              title="Yash Technology"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://scandinaviantech.se/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ST}
              alt="Scandinaviantech"
              title="Scandinaviantech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.ruddersoft.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={rudersoft}
              alt="Rudersoft"
              title="Rudersoft"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.techmahindra.com/en-in/?f=1415427774"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={TM}
              alt="Tech Mahindra"
              title="Tech Mahindra"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.infosys.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Infosys}
              alt="Infosys"
              title="Infosys"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.gitacloud.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={GC}
              alt="Gita Cloud"
              title="Gita Cloud"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.dynproindia.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Dynpro}
              alt="Dynpro"
              title="Dynpro"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.acnovate.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={acnovate}
              alt="Acnovate"
              title="Acconvate"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.sapture.com.au/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={sapture}
              alt="sapture"
              title="Sapture"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.wudza.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={wudza}
              alt="Wudza"
              title="Wudza"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://hicglobalsolutions.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={HIC}
              alt="HIC Global"
              title="HIC Global"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.ats-global.com/services/ats-consulting/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ats}
              alt="ATS Global"
              title="ATS Global"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://aasymtech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Aysmtech}
              alt="Aysmtech"
              title="Aysmtech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="http://linksapjobs.eu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Linksap}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="http://www.logix-tech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logix}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.oxfordcorp.com/en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Oxford}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a href="http://hays.de/" target="_blank" rel="noopener noreferrer">
            <img
              src={Hays}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="http://siatech.asia/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={SiyaTech}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.craveinfotech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={crave}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>

          <a
            href="https://daynilgroup.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={DG}
              alt="Daynilgroup"
              title="Daynilgroup"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://crafsol.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={crafsol}
              alt="Crafsol"
              title="Crafsol"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a href="https://medha.eu/" target="_blank" rel="noopener noreferrer">
            <img
              src={medha}
              alt="Medha Partners"
              title="Medha Partners"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.yash.com/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={yash}
              alt="Yash Technology"
              title="Yash Technology"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://scandinaviantech.se/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ST}
              alt="Scandinaviantech"
              title="Scandinaviantech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.ruddersoft.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={rudersoft}
              alt="Rudersoft"
              title="Rudersoft"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
        </marquee>
      </div>
      {/* <div className="container-fluid marquee">
  <div className="marquee-content">
    <img src={TM} alt="Client 1" className="client-logo" />
    <img src={Infosys} alt="Client 2" className="client-logo" />
    <img src={GC} alt="Client 3" className="client-logo" />
    <img src={Dynpro} alt="Client 4" className="client-logo" />
    <img src={acnovate} alt="Client 5" className="client-logo" />
    <img src={sapture} alt="Client 6" className="client-logo" />
    <img src={wudza} alt="Client 7" className="client-logo" />
    <img src={HIC} alt="Client 8" className="client-logo" />
    <img src={ats} alt="Client 9" className="client-logo" />
    <img src={Aysmtech} alt="Client 10" className="client-logo" />
    <img src={crave} alt="Client 11" className="client-logo" />
    <img src={sai} alt="Client 12" className="client-logo" />
    <img src={DG} alt="Client 13" className="client-logo" />
    <img src={crafsol} alt="Client 14" className="client-logo" />
    <img src={medha} alt="Client 16" className="client-logo" />
    <img src={yash} alt="Client 17" className="client-logo" />
    <img src={ST} alt="Client 18" className="client-logo" />
    <img src={rudersoft} alt="Client 19" className="client-logo" />
    <img src={TM} alt="Client 1" className="client-logo" />
    <img src={Infosys} alt="Client 2" className="client-logo" />
    <img src={GC} alt="Client 3" className="client-logo" />
    <img src={Dynpro} alt="Client 4" className="client-logo" />
    <img src={acnovate} alt="Client 5" className="client-logo" />
    <img src={sapture} alt="Client 6" className="client-logo" />
    <img src={wudza} alt="Client 7" className="client-logo" />
    <img src={HIC} alt="Client 8" className="client-logo" />
    <img src={ats} alt="Client 9" className="client-logo" />
    <img src={Aysmtech} alt="Client 10" className="client-logo" />
    <img src={crave} alt="Client 11" className="client-logo" />
    <img src={sai} alt="Client 12" className="client-logo" />
    <img src={DG} alt="Client 13" className="client-logo" />
    <img src={crafsol} alt="Client 14" className="client-logo" />
    <img src={medha} alt="Client 16" className="client-logo" />
    <img src={yash} alt="Client 17" className="client-logo" />
    <img src={ST} alt="Client 18" className="client-logo" />
    <img src={rudersoft} alt="Client 19" className="client-logo" />
  </div>
</div> */}

      <div className="container-fluid contact-section" id="contact-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <img
                src={contact}
                alt="Contact"
                title="Contact US"
                className="img-fluid"
              />
            </div>
            <div className="col-md-4">
              <h2 className="contact-header">Get In Touch</h2>
              <p className="contact-description">Please fill the below form.</p>
              <form onSubmit={handleSubmit}>
                <div className="">
                  <div className="input-group">
                    <span className="input-group-text input-icon">
                      <img src={user} alt="User Icon" title="user" />
                    </span>
                    <input
                      type="text"
                      className="form-control inputStyle"
                      placeholder="Your Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      style={{ padding: "14px" }}
                      required
                    />
                  </div>
                </div>

                <div className="">
                  <div className="input-group" style={{ flexWrap: "nowrap" }}>
                    <span className="input-group-text input-icon">
                      <img
                        src={services}
                        alt="Services Icon"
                        title="Services"
                      />
                    </span>
                    <select
                      className="inputStyle select-style"
                      name="service"
                      value={formData.service}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Services</option>
                      <option value="web-development">Web Development</option>
                      <option value="app-development">App Development</option>
                      <option value="seo-services">SEO Services</option>
                      <option value="cloud-solutions">Cloud Solutions</option>
                    </select>
                  </div>
                </div>

                <div className="">
                  <div className="input-group">
                    <span className="input-group-text input-icon">
                      <img src={email} alt="Email Icon" title="Email" />
                    </span>
                    <input
                      type="email"
                      className="form-control inputStyle"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      style={{ padding: "14px" }}
                      required
                    />
                  </div>
                </div>

                <div className="">
                  <div className="input-group">
                    <span className="input-group-text input-icon">
                      <img src={phone} alt="Phone Icon" title="phone" />
                    </span>
                    <input
                      type="tel"
                      className="form-control inputStyle"
                      placeholder="Phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      pattern="[0-9]{10}"
                      title="Please enter a valid 10-digit phone number"
                      ref={phoneInputRef}
                      style={{ padding: "14px" }}
                    />
                    {phoneError && (
                      <PhoneErrorPopup
                        message={phoneError}
                        inputRef={phoneInputRef}
                      />
                    )}
                  </div>
                </div>

                <div className="">
                  <div className="input-group">
                    <span className="input-group-text input-icon">
                      <img src={company} alt="Company Icon" title="company" />
                    </span>
                    <input
                      type="text"
                      className="form-control inputStyle"
                      placeholder="Company"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                      style={{ padding: "14px" }}
                      required
                    />
                  </div>
                </div>

                <div className="">
                  <div className="input-group">
                    <span className="input-group-text input-icon1">
                      <img
                        src={message}
                        alt="Message Icon"
                        title="message"
                        className="image"
                      />
                    </span>
                    <textarea
                      className="form-control inputStyle1"
                      placeholder="Message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary button3"
                  alt="Submit"
                  title="Submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Test;
