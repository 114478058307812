import { Carousel } from "react-bootstrap";
import { useRef } from "react";

import bg1 from "../Images/bg1.jpg";
import bg2 from "../Images/bg2.jpg";
import bg3 from "../Images/bg3.jpg";
import bg4 from "../Images/bg4.jpg";
import BlogMainPage from "./BlogMainPage";

const Blogs = () => {
  const carouselRef = useRef(null);

  const handlePrev = () => {
    carouselRef.current.prev();
  };

  const handleNext = () => {
    carouselRef.current.next();
  };
  return (
    <>
      <div className="custom-carousel-container">
        <span
          className="material-symbols-outlined custom-prev"
          onClick={handlePrev}
        >
          arrow_back_ios
        </span>

        <Carousel ref={carouselRef} controls={false} indicators={false}>
          {/* First Slide */}
          <Carousel.Item>
            <img
              src={bg1} // Ensure this is the correct path
              style={{ height: "350px" }}
              className="carouselimg"
              alt="First slide"
            />
            <div className="overlay"></div>

            {/* Text for First Slide */}
            <div className="carousel-heading">
              <h1>SAP IBP</h1>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={bg2} // Ensure this is the correct path
              style={{ height: "350px" }}
              className="carouselimg"
              alt="Second slide"
            />
            <div className="overlay"></div>

            {/* Different Text for Second Slide */}
            <div className="carousel-heading">
              <h1>SAP S/4HANA Implementation</h1>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={bg3} // Ensure this is the correct path
              style={{ height: "350px" }}
              className="carouselimg"
              alt="Second slide"
            />
            <div className="overlay"></div>
            {/* Different Text for Second Slide */}
            <div className="carousel-heading">
              <h1>SAP S/4 Monitoring & Support</h1>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={bg4} // Ensure this is the correct path
              style={{ height: "350px" }}
              className="carouselimg"
              alt="Second slide"
            />
            <div className="overlay"></div>
            {/* Different Text for Second Slide */}
            <div className="carousel-heading">
              <h1>React JS Development</h1>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={bg2} // Ensure this is the correct path
              style={{ height: "350px" }}
              className="carouselimg"
              alt="Second slide"
            />
            <div className="overlay"></div>

            {/* Different Text for Second Slide */}
            <div className="carousel-heading">
              <h1>Java Development</h1>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={bg3} // Ensure this is the correct path
              style={{ height: "350px" }}
              className="carouselimg"
              alt="Second slide"
            />
            <div className="overlay"></div>
            {/* Different Text for Second Slide */}
            <div className="carousel-heading">
              <h1>Dot Net Development</h1>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={bg4} // Ensure this is the correct path
              style={{ height: "350px" }}
              className="carouselimg"
              alt="Second slide"
            />
            <div className="overlay"></div>
            {/* Different Text for Second Slide */}
            <div className="carousel-heading">
              <h1>PHP Development</h1>
            </div>
          </Carousel.Item>
        </Carousel>

        <span
          className="material-symbols-outlined custom-next"
          onClick={handleNext}
        >
          arrow_forward_ios
        </span>
      </div>
      <div className="blogpage">
        <BlogMainPage />
      </div>
    </>
  );
};

export default Blogs;
