
import React from 'react';

import ContactForm from './ContactForm'; // Import the reusable ContactForm component

const Contact = () => {
  // Define service options to be passed as a prop
  const services = [
    { label: 'Web Development', value: 'web-development' },
    { label: 'App Development', value: 'app-development' },
    { label: 'SEO Services', value: 'seo-services' },
    { label: 'Cloud Solutions', value: 'cloud-solutions' },
  ];

  // Handle form submission success callback
  const handleFormSubmitSuccess = () => {
    console.log('Form successfully submitted on Contact Page');
  };

  return (
    <>
      <div className="container-fluid contact-section" id="contact-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              {/* Use the reusable ContactForm component */}
              <ContactForm
                services={services} // Pass service options
                onSubmitSuccess={handleFormSubmitSuccess} // Pass form submit success handler
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
