/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Alerts1 from "./Images/Alerts1.png";
import Alerts2 from "./Images/Alerts2.png";
import Alerts3 from "./Images/Alerts3.png";
import Alerts4 from "./Images/Alerts4.png";
import Alerts5 from "./Images/Alerts5.png";
import Alerts6 from "./Images/Alerts6.png";
import Alerts7 from "./Images/Alerts7.png";
import Alerts8 from "./Images/Alerts8.png";
import Alerts9 from "./Images/Alerts9.png";
import Alerts10 from "./Images/Alerts10.png";
import Alerts11 from "./Images/Alerts11.png";
import Alerts12 from "./Images/Alerts12.png";
const Blogdetails4 = () => {
  return (
    <>
      <div className="container blogdetails">
        <div className="article-header mt-5">
          <h1>Alerts: Custom Aletrs In SAP IBP</h1>
          <br />
          <div className="bloginfo">
            <p>
              <strong>Author Name</strong>:Priya Thakre
            </p>
            <p>Publish Date: September 11, 2024</p>
          </div>
          <p>
            Supply chain problems that are significant or critical, including
            inventory shortages, supply and demand imbalances, or unanticipated
            changes in the supply chain, are detected by custom alerts. Instead
            of creating numerous distinct definitions, it is feasible to
            centralize the definition of custom alerts, applying different
            criteria to different properties and time periods.
            <br />
            You can apply key figure criteria to various time periods (you can
            specify a horizon in which alert is more likely to occur, or is more
            meaningful), or to calculation level attributes (you can specify
            that alert has to be generated, for example, for a certain customer,
            or for a certain location). It gives more flexibility and usability.
            You can include standard and custom alerts to the all the interfaces
            or specific interface in SAP
          </p>
          <br />
          <p>
            <b>Why Custom Alerts Matter:</b>
          </p>
          <br />
          <p>
            Custom alerts are generated by the system and visible to assigned
            users regarding important or critical supply chain situations.
            Information contained in the custom alert allows users to analyze
            and measure the impact of unexpected situations on their business.
            Users can fine- tune the criteria for alerts to be generated to
            mitigate issues in the supply chain. Custom alerts are integrated
            with cases in SAP Supply Chain Control Tower, which facilitates the
            tracking and resolution of supply chain problems.
            <br />
            Alerts and their overview can also be added to dashboards to
            complement the visualization of different aspects of planning in
            organization.
            <br />
            </p>
            <p>
              <b>Understanding SAP IBP Alerts:</b>
            </p>
            <br />
            <p>The apps available for custom alerts include the following:</p>
            <p>§ Define and Subscribe to Custom Alerts</p>
            <p>§ Monitor Custom Alerts</p>
            <p>§ Define Custom Alerts Overview</p>
           <img src={Alerts1}/>
           <p><b>Consider a common scenario:</b>The marketing team is interested in comparing their forecast with the sales team's forecast, which is based on sales history, but the marketing team's forecast is based on promotion. As a result, let's create an alert first to notify the marketing team when their forecast is less than the sales forecast and second when the marketing team's quantity is not null.</p><br/>
           <img src={Alerts2}/>
           <p>Getting started with Custom Alerts:</p>
           <br/>
           <p><b>Define and Subscribe to Custom Alerts:</b>You can setup subscriptions and definitions with this app to calculating custom alerts. Plans, versions, scenarios, time horizons, calculation levels, and static or machine learning rules that determine alerts are all defined. You can share custom alert definitions and subscriptions with other users and user groups.</p><br/>
           <p>When end user configuring alert Following are the steps to take:</p><br/>
           <p>1. Open the Define and Subscribe to Custom Alerts app.</p>
           <p>2. Choose Create.</p>
           <p>3. General Information - Enter the general information about your custom alert definitions.</p><br/>
           <img src={Alerts3}/>
           <p>4. Data Selection - Select the time period and calculation level for which you want to custom alert to be calculated.</p>
           <img src={Alerts4}/>
           <p>5. Alert Rules - Define the rules for the calculation of custom alerts, Define static or machine learning rules or both for the calculation of alerts.</p><br/>
           <img src={Alerts5}/>
           <p>6. Display Options - Define the display options for your custom alerts by selecting a chart type and the corresponding chart options as well as any complementary charts that will help you better understand and analyze the issues.</p>
           <img src={Alerts6}/>
           <p>7.Procedure Playbooks - Assign one or more procedure playbook that can be used to solve the issues detected in the custom alerts calculated for the custom alert definition</p>
           <img src={Alerts7}/>
           <p>8.Sharing - Share the custom alert definition with other users and user groups so that you can share subscription with them or they can create their own subscription to monitor the custom alerts.</p>
           <p>9.Review once, and create your custom alert definition.</p>
           <img src={Alerts8}/>
           <p>10.After review you need to give Subscriptions.</p>
           <img src={Alerts9}/>
           <p><b>Monitor Custom Alerts:</b>The tool allows you to compute real-time alerts that you are subscribed to and present them in an extensive table or chart. To avoid waiting to refresh all subscriptions at once, refresh individual alert subscriptions using the alert menu. Examine the metrics and charts to find and fix any possible supply chain problems.</p>
           <img src={Alerts10}/>
           <p>Results of custom Alerts are:</p>
           <img src={Alerts11}/>
           <p><b>How to Solve an Alert</b></p><br/>
           <p><b>a. Snoozing Alerts:</b>Snoozing alerts provides a flexible solution to temporarily switching alerts off. An alert exists but you're not able to do anything to solve the issues in the short term. You’ve solved the issues and you know the alert will disappear as soon as the data changes. You can reactivate an alert manually to move it from the Snoozed list to the Active list by selecting the Activate button.</p><br/>
           <p>There are several snooze options available:</p><br/>
           <p>Snooze for All Users and User Groups: You have the option to snooze or activate any shared alerts for all users and user groups. This way, alerts are snoozed or activated once for all users who can view them.</p><br/>
           <p>Snooze Indefinitely: The alert is displayed on the Snoozed tab until one of the key figure’s changes</p><br/>
           <p>Snooze Until Data Changes: The alert is displayed on the Snoozed tab until one of the key figure’s changes</p>
           <p>Snooze Until: You can see the alert on the Snoozed list until the date you specify. The notification reappears in the Active list after this date.</p><br/>
           <p><b>Remove Obsolete Snoozed Alerts: </b>The checkbox Remove Obsolete Snoozed Alerts controls where the alert will then be displayed. If you haven't selected the checkbox, the alert appears in the Snoozed list. If you have selected the checkbox, the alert appears in the Active list.</p><br/>
           <p><b>b. Adding Alerts to a Case:</b>Add alerts to a new or an existing case to help resolve supply chain issues. The Case section allows you to view basic information about that case and how close it is to being resolved. You can navigate to the Manage Cases app to work on resolving the issues. Following are steps to add case like:</p><br/>
           <p>If you choose to add the alert to a new case, fill the mandatory fields by providing a name, description, priority, and owner. Optionally, assign the case to a user or user group and save your changes.</p><br/>
           <p>Launch the Manage Cases app by either selecting the link to the case itself in the Case tab of the alert details, or by opening the app from the Fiori launchpad.</p>
           <p>·From the Alerts tab, you can see all the current alerts that have been added to the case in the alert monitor. You can view the alert subscription name, the calculation level, the date on which the alert was added to the case, and the value of the main key figure.</p><br/>
           <p><b>Define Custom Alerts overview: </b>You can use the Define Custom Alerts Overview app to create overviews for your custom alerts that you can add to your dashboard. You can create these overviews either based on subscriptions or other criteria</p>
           <img src={Alerts12}/>
           <p><b>Conclusion</b> The system generates custom notifications about critical or significant supply chain conditions that are visible to designated people. Custom alerts are integrated with cases in SAP Supply Chain Control Tower, which facilitates the tracking and resolution of supply chain problems. Information contained in the custom alert allows users to analyze and measure the impact of unexpected situations on their business</p>
           <p><b>Created By Priya Thakre</b></p>
        </div>
      </div>
    </>
  );
};

export default Blogdetails4;
