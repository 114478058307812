import React from "react";
import { Image } from "react-bootstrap";
import sap from "./Images/SAP.png";
import Carousel from "@itseasy21/react-elastic-carousel";
import review3 from "./review3";
import sapbusiness from "./Images/SAPBusiness.png";
import sapbusiness1 from "./Images/sapbusiness1.png";
import Card from "react-bootstrap/Card";
import sapbusiness2 from "./Images/sapbusiness2.png";
import sapbusiness3 from "./Images/sapbusiness3.png";
import sapbusiness4 from "./Images/sapbusiness4.png";
const SAPbusiness = () => {
  const breakpoints = [
    { width: 2, itemsToShow: 1 },
    { width: 530, itemsToShow: 2 },
    { width: 760, itemsToShow: 3 },
  ];

  return (
    <>
      <div className="container sap">
        <div className="row nativerow">
          <div className="col-md-3">
            <img src={sap} />
          </div>
          <div className="col-md-9">
            <h2>SAP Business One</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid seo">
        <div className="container about">
          <div className="row aboutusrow">
            <div className="col-md-6">
              <img src={sapbusiness} />
            </div>
            <div className="col-md-6">
              <h2>Understanding SAP Business One</h2>
              <p>
                In today's competitive business environment, SAP Business One
                has become indispensable for small and medium-sized enterprises
                (SMEs) seeking effective operations management. This affordable
                and user-friendly business management software streamlines
                critical processes such as finance, sales, inventory, and more.
                Designed for ease of use, SAP Business One can be tailored to
                meet specific business requirements, offering comprehensive
                tools for financial management, sales, procurement, inventory,
                production, and customer relationship management (CRM).
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="main8">
          <div className="SAPIBP">
            <h2>Principal Characteristic Of SAP Business One</h2>
          </div>
          <div className="cls1">
            <Carousel breakPoints={breakpoints}>
              {review3.map((item, i) => (
                <div key={i} className="cardClass1">
                  <div className="dd11">
                    <p className="contentStyle1">{item.title}</p>
                    <div className="ncm">
                      <div className="nnam">
                        <p className="nameStyle1">{item.content}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="container-fluid seo">
        <div className="container sapbusiness">
          <h2>Discover The Comprehensive Solutions For Your Business!</h2>
          <p>
          "Discover The Comprehensive Solutions For Your Business! Whether your small or medium-sized organisation (SME) grows or enters new markets, SAP Business One offers a complete software solution for all key business operations. You can monitor and manage all operations, from purchasing to warehousing and logistics, from manufacturing to sales, with the help of the intelligent, sector-specific SAP solution utilizing a single application. Benefit from streamlined processes and enhanced visibility across your entire business."
          </p>
          <img src={sapbusiness1} />
        </div>
      </div>
      <div className="">
        <div className="container reactcontainer">
          <h2>How To Make The Most Of SAP Business One</h2>
          <p>
          To maximize the benefits of SAP Business One, consider the following strategies:
          </p>
          <div className="row">
            <div className="col-sm-3">
              <Card className="sapcards1">
                <Card.Body>
                  <Card.Title className="reacttitle">
                  Streamlined Operations:
                  </Card.Title>
                  <Card.Text>
                  SAP Business One integrates finance, sales, inventory, and production modules to automate processes and enhance efficiency.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-sm-3">
              <Card className="sapcards1">
                <Card.Body>
                  <Card.Title className="reacttitle">
                  Scalability: 
                  </Card.Title>
                  <Card.Text>
                  Designed for growth, SAP Business One scales seamlessly, accommodating business expansion without compromising performance or stability.                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-sm-3">
              <Card className="sapcards1">
                <Card.Body>
                  <Card.Title className="reacttitle">
                  Training and Support:
                  </Card.Title>
                  <Card.Text>
                  Investing in comprehensive training enables teams to maximize SAP Business One's capabilities, supported by robust resources for timely issue resolution and ongoing support.</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-sm-3">
              <Card className="sapcards1">
                <Card.Body>
                  <Card.Title className="reacttitle">
                  Reporting and Analytics:
                  </Card.Title>
                  <Card.Text>
                  SAP Business One offers powerful BI tools for real-time insights, empowering data-driven decisions and strategic business planning.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid nodecolor1">
        <div className="container node">
          <h2>Benefits Of SAP Business One For Business</h2>
        
          <div className="row">
            <div className="col-md-6">
              <div className="nodeinfo">
                <div className="nodecontent">
                  <span className="businessspan">
                    <img src={sapbusiness2} className="businessimg" />
                  </span>
                  <div className="subinfo">
                    <p>
                      SAP Business One enables businesses to streamline their
                      operations, reduce manual tasks, and improve efficiency.
                      It automates critical business processes such as finance,
                      sales, and inventory management, enabling businesses to
                      focus on other aspects of their operations.
                    </p>
                  </div>

                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="nodeinfo">
                <div className="nodecontent">
                  <span className="businessspan">
                    <img src={sapbusiness3} className="businessimg" />
                  </span>
                  <div className="subinfo">
                    <p>
                      SAP Business One provides businesses with real-time
                      insights into their operations, enabling them to make
                      better decisions. It provides comprehensive reports and
                      dashboards that offer a complete view of the business,
                      enabling businesses to identify areas that require
                      improvement and make informed
                    </p>
                  </div>

                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="nodeinfo">
                <div className="nodecontent">
                  <span className="businessspan">
                    <img src={sapbusiness3} className="businessimg" />
                  </span>
                  <div className="subinfo">
                    <p>
                      SAP Business One provides businesses with tools to manage
                      their customer relationships effectively. It enables
                      businesses to track customer interactions, Customer manage
                      customer data, and personalise communication, enhancing
                      the overall customer experience.
                    </p>
                  </div>

                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container sapbusiness">
        <h2>The Future Of SAP Business One</h2>
        <p>
          As technology continues to evolve at a rapid pace, it's no surprise
          that SAP Business One is set to see some major changes in the coming
          years. Here are just a few of the key developments we expect to see in
          2023:
        </p>
        <div className="row">
          <div className="col-sm-4">
            <Card className="sapcards1">
              <Card.Body>
                <Card.Title className="reacttitle">
                  Increased Use of AI and Machine Learning
                </Card.Title>
                <Card.Text>
                AI and machine learning will play a larger role in SAP Business One, leveraging data processing capabilities to revolutionize business operations.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-4">
            <Card className="sapcards1">
              <Card.Body>
                <Card.Title className="reacttitle">
                  Greater Emphasis on Cloud-Based Solutions
                </Card.Title>
                <Card.Text>
                The shift towards cloud-based solutions will intensify, offering scalability, flexibility, and cost-effectiveness for businesses adopting SAP Business One.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-4">
            <Card className="sapcards1">
              <Card.Body>
                <Card.Title className="reacttitle">
                  Enhancing The Consumer Experience
                </Card.Title>
                <Card.Text>
                Improvements in usability and accessibility will enhance the overall user experience of SAP Business One, enabling businesses to maximize its capabilities effortlessly.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default SAPbusiness;
