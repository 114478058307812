/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import product1 from "./Images/product1.png";
import product2 from "./Images/product2.png";
import product3 from "./Images/product3.png";
import product4 from "./Images/product4.png";
import product5 from "./Images/product5.png";
import product6 from "./Images/product6.png";
import product7 from "./Images/product7.png";
import product8 from "./Images/product8.png";
import product9 from "./Images/product9.png";
import product10 from "./Images/product10.png";
import product11 from "./Images/product11.png";
import product12 from "./Images/product12.png";
import product13 from "./Images/product13.png";
const Blogdetails5 = () => {
  return (
    <>
      <div className="container blogdetails">
        <div className="article-header mt-5">
          <h1>Manage Product Lifecycle Planning</h1>
          <br />
          <div className="bloginfo">
            <p>
              <strong>Author Name</strong>:Priya Thakre
            </p>
            <p>Publish Date: September 11, 2024</p>
          </div>

          <p>Introduction</p>
          <p>
            Historic data is typically unavailable while developing a new
            product, and due to a lack of sales history, forecasting is
            difficult. Therefore, you look for similar products whose historical
            sales data you can use instead, designating these products as
            reference products, in order to get accurate estimates for the
            future demand of a new product.
          </p>
          <p>
            IBP does not have a separate lifecycle planning run. Lifecycle
            planning happens along with ‘Statistical forecasting’ run. To enable
            lifecycle planning, the "General" tab's highlighted checkbox in the
            "Manage Forecast Models" app must be checked.
          </p>
          <p>
            All product have phase-in and phase-out stages in their lifecycle
            and have period of maturity in between two. Utilization of product
            Lifecycle Management. Following graph shows how product sales varies
            at every stage.
          </p>
          <img src={product1}  />

          <p>
            <b>Product Lifecycle Stages</b>
          </p>
          <p>
            Two life cycle stages launch and growth are covered by the phase in
            curve. Forecast based on reference products historical sales with
            correction based on phase in curve. The product release into the
            market for the 1st time, consumer start taking the product and
            buying it, the product concept is proven it becomes more popular
            Once it reaches the mature stage, we can observe trends and
            seasonality. Forecasts are made using the historical sales of
            reference products without any adjustments. Finally, there comes out
            the phase out curve which is in decline stage, weather the Forecast
            based on reference products historical sales with correction based
            on phase out curve.
          </p>
          <br />
          <h3>
            <b>Process of New Product Introduction</b>
          </h3>

          <p>Create planning objects for new products.</p>
          <p>
            Create a 'New Product Assignment' profile with launch dimensions.
          </p>
          <p>
            Define reference products for the new product, along with weightage
            factors, offsets, and validity periods.
          </p>
          <p>Select the forecast start date and phase-in/phase-out dates.</p>
          <p>
            Create a forecast model in the 'Manage Forecast Models' app and
            select the Consider product lifecycle information option
          </p>
          <p>
            Once the phase-in stage ends, the product starts using its own sales
            history unless otherwise specified.
          </p>

          <p>
            <b>Setting for Product Lifecycle</b>
          </p>
          <p>
            First you have to maintain master data and planning objects for new
            products. The SAP IBP Product Lifecycle application gives users the
            ability to choose and utilize history from one or more products by
            assigning a weight to each one, Over 100% of the weighting may be
            applied to a single reference product.
          </p>
          <p>
            As you can see below, there is one new products FERT11XPRO.
            FERT11XPRO will use 100% history of existing product Z-Mobile1. The
            results below are the output of running a statistical forecast using
            these setting
          </p>
          <img src={product2} />

          <p>
            In this case, the main figures "Delivered qty" and "Statistical
            Forecast Qty" represent the historical sales and statistical
            forecast, respectively.
          </p>
          <img src={product3} />
          <p>
            SAP Fiori app in Demand planner Go to “Manage Product Lifecycle” app
            in SAP Fiori to create new product.
          </p>
          <img src={product4} />
          <p>
            In manage product lifecycle app you have to define the processes and
            specify the settings available for single and multiple product
            assignment. Then, after selecting the planning area, you have to add
            phase in and phase out. There are more options, as you can see in
            the below image
          </p>
          <img src={product5} />
          <p>
            <b>Product Assignment</b>
          </p>
          <p>
            Select the planning area for product lifecycle management and define
            the attributes as assignment levels.
          </p>
          <br />
          <p>
            Add reference product ID with 100 % weightage and its validity also
            then add offset periods in past, check status.
          </p>
          <br />
          <p>
            Some standard settings are their like add, delete, edit, activate
            and deactivate and also show reference
          </p>
          <br />
          <p>
            Manage Lifecycle’ app also provides graphical representation of
            reference product history which will help planners to visually check
            history of reference products seasonality, trend etc.
          </p>
          <br />
          <img src={product6} />
          <img src={product7} />
          <p>
            <b>Forecast Dates - Phase-In and Phase-Out</b>
          </p>
          <br />
          <p>
            Add phase- in start date from which the new product is actually sold
            to the customer. The system does not generate any forecasts for time
            periods that lie before this date.
          </p>
          <p>
            When the product has reached maturity at the period indicated by the
            phase-in end date. Once the phase-in end is reached in the
            historical horizon of the forecast, forecasting is dependent on the
            product's own sales history.
          </p>
          <p>
            Add phase-out start date defines the date from which the product
            will be gradually out from the market. After phase-out end date the
            product will no longer be sold. The system does not produce any
            forecasts after this date and delete all values that already exist
            in the forecast horizon.
          </p>
          <img src={product8} />
          <p>
            <b>Create forecast model</b>
          </p>
          <br />
          <p>
            Go to “Manage Forecast Model”, as follows make sure the checkbox to
            consider lifecycle planning during statistical forecasting run.
          </p>
          <img src={product9} />
          <p>Go to Excel Add in, Run Statistical Forecasting</p>
          <br />
          <p>
            After forecasting show the result Phase-in and Phase-out according
            to time period, you will see that z-mobile1 is used as a reference
            for FERT11XPRO starting Mar 2024, for planning combinations with
            LocationID. While for every other combination z-mobile1 is used as
            the reference.
          </p>
          <br />
          <p>
            <b>Phase in results in excel add in</b>
          </p>
          <img src={product10} />
          <p>
            <b>Phase out results in excel add in</b>
          </p>
          <img src={product11} />
          <p>
            <b>Curves</b>
          </p>
          <br />
          <p>
            <b>Phase-In and Phase-Out Curves</b>
          </p>
          <br />
          <p>
            The Phase-In and Phase-Out curves display how we expect the demand
            for a product to change when it's introduced and phased out. These
            curves are like charts that show the percentage of demand over time
            during these phases.
            <br />
            Normally, the starting period is set as 0.1, and the ending period
            is set as 0.9.
            <br />
            These percentages are used to calculate the forecast result during
            the phase-in time period, which is determined by the specified
            phase-in start and end dates. There are three options of curves can
            be defined.
          </p>
          <p>
            <b>Linear: </b>The straight-line curve is figured out by drawing a
            straight line between 0.1 and 0.9.
          </p>
          <p>
            <b>sublinear: </b>The sublinear curve uses a quadratic function to
            analyze the time series. It involves drawing a straight line between
            0.316 and 0.949. The limits are determined by applying the reverse
            of this function to the standard limits, which are 0.1 and 0.9.
          </p>
          <br />
          <p>
            <b> Superlinear: </b>The superlinear curve uses a square root
            function on the time series. It involves drawing a straight line
            between 0.01 and 0.81. To determine the limits, the reverse of the
            square root function is applied to the standard boundaries, which
            are 0.1 and 0. 9.
          </p>
          <br />
          <p>
            <b>Phase-In Curves</b>
          </p>
          <img src={product12} />
          <p>
            <b>Phase-out Curves</b>
          </p>
          <img src={product13} />

          <p>
            <b>Conclusion</b>
          </p>
          <p>
            SAP IBP product lifecycle management capabilities empower businesses
            to navigate the complexities of the market by providing a holistic
            view of their products from inception to retirement. By leveraging
            historical data, facilitating collaboration, and offering real-time
            analytics, SAP IBP becomes an invaluable tool for organizations
            seeking to optimize their product lifecycle strategies.
          </p>
          <br />
          <p>
            <b>Created by Priya Thakre</b>
          </p>
        </div>
      </div>
    </>
  );
};

export default Blogdetails5;
