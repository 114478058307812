/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";

function CommentSection({ blogId }) {
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [message, setMessage] = useState("");
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  useEffect(() => {
    checkUserAuthentication();
    fetchComments();
  }, [blogId]);

  const fetchComments = () => {
    axios
      .get(`https://blog-backend-ivfv.onrender.com/blogs/${blogId}/comments`)
      .then((response) => {
        setComments(response.data);
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
      });
  };

  const checkUserAuthentication = () => {
    console.log("cookie-");
    //Check if user is authenticated using cookies
    console.log(document.cookie);
    const isAuthenticated = document.cookie.includes("authenticated=true");
    setUserAuthenticated(isAuthenticated);
    console.log("User authenticated:", isAuthenticated);
  };

  const handleCommentSubmit = () => {
    if (!commentText.trim()) {
      setMessage("Please fill in the comment field.");
      return;
    }

    axios
      .post(
        `https://blog-backend-ivfv.onrender.com/blogs/${blogId}/comments`,
        {
          text: commentText,
        },
        {
          withCredentials: true, //Include cookies in the request
        }
      )
      .then(() => {
        fetchComments();
        setMessage("");
        setCommentText("");
      })
      .catch((error) => {
        console.error("Error posting comment:", error);
        setMessage("Failed to submit comment. Please try again later.");
      });
  };

  const handleLogout = () => {
    axios
      .get("https://blog-backend-ivfv.onrender.com/logout")
      .then(() => {
        setUserAuthenticated(false);
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  return (
    <div className="comment-section">
      {comments.length === 0 && <p>Be the first one to comment.</p>}
      <div className="commentmainsec">
        <h4>Comments</h4>
        {comments.map((comment) => (
          <div key={comment._id} className="comment">
            <span className="bloguser">
              <img
                src={comment.image}
                style={{
                  width: "50px",
                  borderRadius: "50%",
                  marginRight: "20px",
                }}
                alt="Profile"
              />
              {comment.displayName}{" "}
              <span className="timesec">
                {" "}
                {moment(comment.createdAt).fromNow()}{" "}
              </span>
            </span>
            <p>{comment.text}</p>
            <p></p>
          </div>
        ))}
      </div>
      {userAuthenticated ? (
        <div className="comment-input">
          <textarea
            rows="4"
            cols="50"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            placeholder="Write your comment here..."
          />
          <button onClick={handleCommentSubmit}>Submit</button>
          <button onClick={handleLogout}>Logout</button>
          <p className="error">{message}</p>
        </div>
      ) : (
        <div>
          <p>Sign in with Google to add a comment:</p>
          <Link to="/google-login">Sign in with Google</Link>
        </div>
      )}
    </div>
  );
}

export default CommentSection;
