
import React,{useState} from "react";
import RPA1 from "./Images/RPA.jpeg";
import { Image } from "react-bootstrap";
import logo11 from "./Images/arrow_outward.png";
const RPA = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className="container rpa">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={RPA1} height={300}/>
          </div>
          <div className="col-md-8">
            <h2>RPA Implementation</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid rpacolor">
        <div className=" container rpainfo">
          <h2>What is RPA ?</h2>
          <p>
          "RPA, which stands for Robotic Process Automation, is a technology that utilizes software robots to automate repetitive tasks typically performed by humans on computers. These tasks include activities such as data entry, file movement, and form filling. Businesses find RPA invaluable as it frees up employees to focus on more critical tasks. Additionally, RPA enhances accuracy and efficiency since robots do not make human errors.                   It falls under the category of business process automation
          {!isExpanded ? (
              <span className="dots">...</span>
            ) : (
              <span className="more">
 (BPA) but differs from AI (Artificial Intelligence) as it operates on rules rather than machine learning."
              </span>
            )}
          </p>
          <button className="sapbtn" onClick={toggleExpand}>
            {isExpanded ? "Read Less" : "Read More"}
            <Image src={logo11} className="img0" alt="Read more icon" />
          </button>
        </div>
      </div>
      <div className="container rpa2">
        <h2>How RPA Boosts Your Business</h2>
        <p>
          Let's move beyond the basics! RPA offers a much wider range of
          automation capabilities than what's being mentioned here.
        </p>
        <div className="row rparow">
          <div className="rparow1">
            <div className="col-md-12">
              <div className="rpainfo1">
                {" "}
                <div className="rpasubinfo1">
                  <h4>Reliability and Transparency</h4>
                  <p>
                    RPA closes data gaps between diverse sources and keeps a log
                    of every step the software robots take when automating
                    processes. This enables staff members to continuously
                    conduct internal evaluations and proactively identify and
                    manage any compliance issues. These RPA features serve
                    businesses in a variety of industries, whether they are
                    insurance providers required to comply with legal
                    requirements, healthcare providers required to adhere to
                    HIPAA privacy laws, or financial services organizations
                    required to be PCI compliant.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="rpainfo1">
                {" "}
                <div className="rpasubinfo1">
                  <h4>Non-Intrusiveness And Compatibility With Already-Installed Systems</h4>
                  <p>
                  RPA interacts with data on the presentation layer of platforms and applications because it imitates human activities. RPA now behaves at the user interface exactly as a human would. Because of this, organizations deploying RPA do not need to make changes to their current legacy systems. This is advantageous because it enables businesses to employ RPA in a nondisruptive manner, setting it apart from other forms of automation. It also lessens the need for staff to be proficient coders and for frequent IT participation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="rpainfo1">
                {" "}
                <div className="rpasubinfo1">
                  <h4>Improved Managerial Skills</h4>
                  <p>
                  Organizations can remotely model, monitor, control, schedule, and carry out the deployment of RPA software robots thanks to a single administration platform. Additionally, it enables auditing and analytics to take place throughout one location. RPA enables enterprises to achieve greater governance so that business processes may be managed more effectively since specific needs can be incorporated into automation rules. High-security security standards can also be preserved by giving the software robots reverse proxy control.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="rpainfo1">
                {" "}
                <div className="rpasubinfo1">
                  <h4>Enhanced Customer Experience</h4>
                  <p>
                  RPA is typically thought of as a solution for businesses to relieve people of the stress of repetitive, high-volume operations like processing claims and issuing purchase orders. RPA can lead to improvements for consumers even though it may appear that the automation of back-office chores has no effect on the front office. RPA enables businesses to provide clients with higher-quality services faster because of its automation capabilities.                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RPA;
