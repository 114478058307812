/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link } from 'react-router-dom'; 
import React, { useState } from 'react';
import Barde from "./Images/Barde.png";
import IBPService from "./Images/IBPService.jpg";
import HanaServices from "./Images/HanaService.jpg";
import MonitoringService from "./Images/MonitoringService.jpg";
import NodeJsService from "./Images/NodeJsService.jpg";
import JavaService from "./Images/JavaService.jpg";
import ReactjsService from "./Images/ReactjsService.jpg";
import DotNetService from "./Images/DotNetService.jpg";
import PHPService from "./Images/PHPService.jpg";
import AngularService from "./Images/AngularService.jpg";
import WordpressService from "./Images/WordpressService.jpg";
import { FaSearch } from 'react-icons/fa'; // Import search icon

const imageData = [
    { src: Barde, category: "React", path: "/barde" },
    { src: HanaServices, category: "Wordpress", path: "/TCApp" },
    { src: IBPService, category: "PHP", path: "/Bestowal" },
    { src: MonitoringService, category: "E-Commerce", path: "/e-commerce" },
    { src: NodeJsService, category: "CMS", path: "/cms" },
    { src: JavaService, category: "React", path: "/react" },
    { src: ReactjsService, category: "Wordpress", path: "/wordpress" },
    { src: DotNetService, category: "PHP", path: "/php" },
    { src: PHPService, category: "E-Commerce", path: "/ecommerce" },
    { src: AngularService, category: "CMS", path: "/cms" },
    { src: WordpressService, category: "React", path: "/react" },
    { src: Barde, category: "Wordpress", path: "/wordpress" }
];

const Tabbing = () => {
    const [activeTab, setActiveTab] = useState("All"); // Set initial active tab

    const handleTabClick = (tab) => {
        setActiveTab(tab); // Update the active tab state
    };

    // Filter images based on the active tab
    const filteredImages = imageData.filter((image) => 
        activeTab === "All" || image.category === activeTab
    );

    return (
        <>
            <div className='container tabbing'>
                <ul className='nav nav-tabs'>
                    {["All", "React", "Wordpress", "PHP", "E-Commerce", "CMS"].map((tab) => (
                        <li className='tab-item' key={tab}>
                            <a
                                className={`tab-link ${activeTab === tab ? 'active' : ''}`}
                                href='#'
                                data-filter={tab}
                                onClick={() => handleTabClick(tab)}
                            >
                                {tab}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>

            <hr className='full-width-hr' />
            <div className='container tab-cards'>
                <div className='tabrow'>
                    {filteredImages.map((image, index) => (
                        <div className='col-md-4' key={index} style={{ position: 'relative' }}>
                            <Link to={image.path}> {/* Use Link for navigation */}
                                <img src={image.src} alt={`Image for ${image.category}`} className='tab-image' />
                                <div className='overlay'>
                                    <FaSearch className='search-icon' />
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Tabbing;
