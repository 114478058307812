import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import CommentSection from "./CommentSection";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";
import "../App.css";
import Breadcrumb from "./Breadcrumb";

function BlogDetails() {
  const [blog, setBlog] = useState(null);
  const { id } = useParams();
  const [comments, setComments] = useState([]);
  const [commentsLoaded, setCommentsLoaded] = useState(false);

  let profilePicURL = null;

  useEffect(() => {
    axios
      .get(`https://blog-backend-ivfv.onrender.com/blogs/${id}`)
      .then((response) => {
        setBlog(response.data);
      })
      .catch((error) => {
        console.error("Error fetching blog details:", error);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(`https://blog-backend-ivfv.onrender.com/comments/${id}`)
      .then((response) => {
        setComments(response.data);
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
      });
  }, [id]);

  const loadComments = () => {
    setCommentsLoaded(true);
  };

  if (!blog) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  if (blog.author && blog.author.profilePic) {
    profilePicURL = `https://blog-backend-ivfv.onrender.com/${blog.author.profilePic}`;
  }

  return (
    <div className="container my-5">
      <Breadcrumb blogTitle={blog.title} />

      <div className="row">
        {/* Left Column: Blog Content */}
        <div className="col-lg-8 col-md-7 col-sm-12 mb-4 detailsLeft">
          <h1 className="display-5 mt-4">{blog.title}</h1>
          <p className="text-muted publish_wrap">
            Published on: {new Date(blog.createdAt).toLocaleDateString()}
          </p>

          {/* Blog Image */}
          {blog.image && (
            <img
              src={`data:image/jpeg;base64,${blog.image}`}
              alt="Blog"
              className="img-fluid mb-4 rounded"
            />
          )}

          {/* Blog Content */}
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{ __html: blog.content }}
          ></div>
        </div>

        {/* Right Column: Author Info, Share Buttons, and Comments */}
        <div className="col-lg-4 col-md-5 col-sm-12 rightWrapper">
          {/* Author Info */}
          <div className="card mb-4">
            <div className="card-body d-flex align-items-center">
              {profilePicURL ? (
                <img
                  src={profilePicURL}
                  alt="Author Profile"
                  className="rounded-circle me-3  mb-3"
                  style={{ width: "60px", height: "60px" }}
                />
              ) : (
                <div
                  className="rounded-circle bg-secondary me-3 mb-3"
                  style={{ width: "60px", height: "60px" }}
                ></div>
              )}
              <div>
                <h6 className="mb-1">{blog.author?.name || "Anonymous"}</h6>
                <p className="text-muted mb-0">
                  {blog.author?.description || "Author description"}
                </p>
              </div>
            </div>
          </div>

          {/* Share Buttons */}
          <div className="card mb-4 text-center">
            <div className="card-body">
              <h6 className="mb-3">Share this blog</h6>
              <div className="d-flex justify-content-center gap-3">
                <FacebookShareButton
                  url={window.location.href}
                  quote={blog.title}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton
                  url={window.location.href}
                  title={blog.title}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={window.location.href}
                  title={blog.title}
                  summary={blog.summary}
                >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </div>
            </div>
          </div>

          {/* Comments Section */}
          <div className="card text-center commentbox">
            <div className="card-body">
              <h6 className="mb-4">Comments</h6>
              {comments.map((comment) => (
                <div key={comment.id} className="mb-3">
                  <div className="d-flex align-items-start">
                    <div
                      className="rounded-circle bg-secondary me-3"
                      style={{ width: "40px", height: "40px" }}
                    ></div>
                    <div>
                      <h6 className="mb-1">{comment.user || "Anonymous"}</h6>
                      <p className="mb-0">{comment.text}</p>
                    </div>
                  </div>
                </div>
              ))}

              {!commentsLoaded && (
                <button
                  className="btn btn-primary mt-3 w-50"
                  onClick={loadComments}
                >
                  Load Comments
                </button>
              )}

              {commentsLoaded && <CommentSection blogId={id} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetails;
